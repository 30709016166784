import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import { getDistinctTaxref } from "./distinctTaxrefProvider";
import CopyButton from "../../components/CopyButton";

import { GBIFexplorerComponent, GBIFData } from "./GBIFexplorerComponent";
import Popup from "../../components/Popup";

function Filters({
  onTaxrefChange,
}: {
  onTaxrefChange: (taxref: string) => void;
}) {
  const [taxref, setTaxref] = useState("");

  const handleTaxrefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxref(e.target.value);
    onTaxrefChange(e.target.value);
  };

  return (
    <div
      style={{
        paddingBottom: "10px",
        textAlign: "left",
        width: "80%",
        margin: "0 auto",
      }}
    >
      <input
        type="text"
        placeholder="Taxref E1C"
        onChange={handleTaxrefChange}
      />
    </div>
  );
}

function Content({
  distinctTaxref,
  onTaxrefChange,
}: {
  distinctTaxref: any[];
  onTaxrefChange: (taxref: string) => void;
}) {

  const [showPopup, setShowPopup] = useState(false);
  const [taxrefToEdit, setTaxrefToEdit] = useState("");

  const handleTaxrefChange = (taxref: string) => {
    onTaxrefChange(taxref);
  };

  function selectSpecieCallback(gbifData: GBIFData) {
    console.log(gbifData);
    setShowPopup(false);
  }

  function editTaxref(taxref: string) {
    setTaxrefToEdit(taxref);
    setShowPopup(true);
  }

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Filters onTaxrefChange={handleTaxrefChange} />
      <table className="admin-table" style={{ width: "80%", margin: "0 auto" }}>
        <thead>
          <tr>
            <th>Taxref E1C</th>
            <th>GBIF species infos (todo)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {distinctTaxref.map((t) => (
            <tr key={t.taxref}>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{t.taxref}</span>
                  <CopyButton val={t.taxref} />
                </div>
              </td>
              <td>--</td>
              <td>
                <i
                  className="fa fa-edit"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    editTaxref(t.taxref);
                  }}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showPopup && (
        <Popup
          title="Test"
          style={{
            width: "80%",
            margin: "0 auto",
            maxWidth: "5000px",
            height: "800px",
            overflow: "auto",
          }}
          onClose={() => setShowPopup(false)}
        >
          <GBIFexplorerComponent
            allowSelectSpecie={true}
            selectSpecieCallback={selectSpecieCallback}
            initialSearchTerm={taxrefToEdit}
          />
        </Popup>
      )}
    </div>
  );
}

function DistinctTaxref() {
  const [loading, setLoading] = useState(true);
  const [distinctTaxref, setDistinctTaxref] = useState([]);
  const [filteredTaxref, setFilteredTaxref] = useState([]);

  useEffect(() => {
    getDistinctTaxref().then((distinctTaxref) => {
      setDistinctTaxref(distinctTaxref);
      setFilteredTaxref(distinctTaxref);
      setLoading(false);
    });
  }, []);

  const handleTaxrefChange = (taxref: string) => {
    const filtered = distinctTaxref.filter((t) =>
      t.taxref.toLowerCase().includes(taxref.toLowerCase())
    );
    console.log(filtered);
    setFilteredTaxref(filtered);
  };

  return (
    <div className="mainpage">
      <div
        style={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "bold",
          padding: "10px",
        }}
      >
        Taxref distincts
      </div>
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <Content
            distinctTaxref={filteredTaxref}
            onTaxrefChange={handleTaxrefChange}
          />
        )}
      </div>
    </div>
  );
}

export default DistinctTaxref;
