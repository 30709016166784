import React, { useState, useEffect } from "react";
import {
  getDashboards,
  setDashboards as setDashboardsApi,
} from "../../dataProvider/dashboardProvider";
import { getClientName } from "../../ConfigProvider";
import Spinner from "../../components/Spinner";
import Popup from "../../components/Popup";
import Info from "../../components/Info";

interface DashboardInfo {
  label: string;
  id: string;
  image: string;
  onlyAdmin: boolean;
}

function DashboardRow({
  dashboard,
  deleteDashboardCallback,
  editDashboardCallback,
}: {
  dashboard: DashboardInfo;
  deleteDashboardCallback: (dashboard: DashboardInfo) => void;
  editDashboardCallback: (dashboard: DashboardInfo) => void;
}) {
  return (
    <tr>
      <td
        style={{
          border: "1px solid black",
          padding: "8px",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {dashboard.id}
      </td>
      <td
        style={{
          border: "1px solid black",
          padding: "8px",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {dashboard.label}
      </td>
      <td
        style={{
          border: "1px solid black",
          padding: "8px",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {dashboard.image}
      </td>
      <td style={{
          border: "1px solid black",
          padding: "8px",
          color: "red",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <img
          src={"/dashboardImages/" + dashboard.image}
          alt="(not found)"
          style={{ width: "100px" }}
        />
      </td>
      <td
        style={{
          border: "1px solid black",
          padding: "8px",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        {dashboard.onlyAdmin ? "X" : ""}
      </td>

      <td
        style={{
          border: "1px solid black",
          padding: "8px",
          textAlign: "center",
          width: "10px",
        }}
      >
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-edit"
          aria-hidden="true"
          onClick={() => {
            editDashboardCallback(dashboard);
          }}
        ></i>
      </td>
      <td
        style={{
          border: "1px solid black",
          padding: "8px",
          textAlign: "center",
          width: "10px",
        }}
      >
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-trash"
          aria-hidden="true"
          onClick={() => {
            if (confirm("Are you sure you want to delete this dashboard?")) {
              deleteDashboardCallback(dashboard);
            }
          }}
        ></i>
      </td>
    </tr>
  );
}

function DashboardTable({
  dashboards,
  deleteDashboardCallback,
  editDashboardCallback,
}: {
  dashboards: DashboardInfo[];
  deleteDashboardCallback: (dashboard: DashboardInfo) => void;
  editDashboardCallback: (dashboard: DashboardInfo) => void;
}) {
  console.log("dashboards", dashboards);
  if (dashboards.length === 0) {
    return <div>No dashboards found</div>;
  }
  return (
    <table style={{ width: "70%", alignItems: "center" }}>
      <thead>
        <tr
          style={{
            border: "1px solid black",
            padding: "8px",
            fontWeight: "bold",
          }}
        >
          <th style={{ border: "1px solid black", padding: "8px" }}>Id</th>
          <th style={{ border: "1px solid black", padding: "8px" }}>Label</th>
          <th style={{ border: "1px solid black", padding: "8px" }}>Image</th>
          <th style={{ border: "1px solid black", padding: "8px" }}>
            Image preview
          </th>
          <th style={{ border: "1px solid black", padding: "8px" }}>
            Uniquement Admin
            <Info text="Si coché, le dashboard n'est visible que pour les utilisateurs admin" />
          </th>
          <th style={{ border: "1px solid black", padding: "8px" }}></th>
          <th style={{ border: "1px solid black", padding: "8px" }}></th>
        </tr>
      </thead>
      <tbody>
        {dashboards.map((dashboard) => (
          <DashboardRow
            key={dashboard.id}
            dashboard={dashboard}
            deleteDashboardCallback={deleteDashboardCallback}
            editDashboardCallback={editDashboardCallback}
          />
        ))}
      </tbody>
    </table>
  );
}

function DashboardConfig() {
  const [dashboards, setDashboards] = useState<DashboardInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [clientName, setClientName] = useState(null);
  const [showAddDashboardPopup, setShowAddDashboardPopup] = useState(false);
  const [dashboardToEdit, setDashboardToEdit] = useState<DashboardInfo | null>(
    null
  );

  useEffect(() => {
    getClientName().then(setClientName);
  }, []);

  async function getDashboardsAsync() {
    setLoading(true);
    const dashboards = await getDashboards();
    if (dashboards) {
      setDashboards(dashboards);
    }
    setLoading(false);
  }

  useEffect(() => {
    getDashboardsAsync();
  }, []);

  function getNewDashboard(): DashboardInfo {
    return {
      label: "",
      id: "",
      image: "",
      onlyAdmin: false,
    };
  }

  function addDashboard() {
    setDashboardToEdit(getNewDashboard());
    setShowAddDashboardPopup(true);
  }

  async function saveDashboard() {
    let newDashboards = [...dashboards];
    const index = newDashboards.findIndex((d) => d.id === dashboardToEdit?.id);
    if (index !== -1) {
      newDashboards[index].label = dashboardToEdit.label;
      newDashboards[index].image = dashboardToEdit.image;
      newDashboards[index].onlyAdmin = dashboardToEdit.onlyAdmin;
    } else {
      newDashboards.push(dashboardToEdit);
    }

    setShowAddDashboardPopup(false);
    setLoading(true);
    await setDashboardsApi(newDashboards);
    getDashboardsAsync();
    setLoading(false);
  }

  async function deleteDashboard(dashboard: DashboardInfo) {
    setLoading(true);
    const newDashboards = dashboards.filter((d) => d.id !== dashboard.id);
    setDashboards(newDashboards);
    await setDashboardsApi(newDashboards);
    getDashboardsAsync();
    setLoading(false);
  }

  function editDashboard(dashboard: DashboardInfo) {
    setDashboardToEdit(dashboard);
    setShowAddDashboardPopup(true);
  }

  return (
    <div
      className="mainpage"
      style={{
        alignItems: "center",
        textAlign: "center",
        alignContent: "center",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2 style={{ textAlign: "center", padding: "10px" }}>
        Dashboards pour le site {clientName}
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <DashboardTable
          dashboards={dashboards}
          deleteDashboardCallback={deleteDashboard}
          editDashboardCallback={editDashboard}
        />
      )}
      <button
        style={{
          padding: "5px 10px",
          fontSize: "16px",
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          marginTop: "30px",
        }}
        onClick={() => addDashboard()}
      >
        Ajouter un dashboard
      </button>
      {showAddDashboardPopup && (
        <Popup
          title="Ajouter un dashboard"
          onClose={() => setShowAddDashboardPopup(false)}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <label>Label:</label>
            <input
              type="text"
              value={dashboardToEdit?.label}
              onChange={(e) =>
                setDashboardToEdit({
                  ...dashboardToEdit,
                  label: e.target.value,
                })
              }
            />
            <br />
            <label>Id:</label>
            <input
              type="text"
              value={dashboardToEdit?.id}
              onChange={(e) =>
                setDashboardToEdit({ ...dashboardToEdit, id: e.target.value })
              }
            />
            <br />
            <label>Image:</label>
            <input
              type="text"
              value={dashboardToEdit?.image}
              onChange={(e) =>
                setDashboardToEdit({
                  ...dashboardToEdit,
                  image: e.target.value,
                })
              }
            />
            <br />
            <label>Uniquement Admin:</label>
            <input
              type="checkbox"
              checked={dashboardToEdit?.onlyAdmin}
              onChange={(e) =>
                setDashboardToEdit({
                  ...dashboardToEdit,
                  onlyAdmin: e.target.checked,
                })
              }
            />
            <br />
            <button
              style={{
                padding: "5px 10px",
                fontSize: "16px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "30px",
              }}
              onClick={saveDashboard}
            >
              Enregistrer
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default DashboardConfig;
