import React, { useState, useEffect } from "react";
import { getClientName } from "../ConfigProvider";
import "./sitedashboard.css";

const __sensorsstats__ = {
  louisedelavalliere: {
    client: "louisedelavalliere",
    configcategory: "sensors",
    sensors: [
      {
        name: "B-LOUISE4 Point B - Zone Beta",
        type: "bioacoustique",
        lat: 47.484734,
        lng: 0.899455,
        ref: "BLOUISE4ZB",
        startdate: "08/11/2024",
        enddate: "",
        id: "nKNfSk1T",
      },
      {
        name: "BLOUISE3 Point A - Zone Alpha",
        type: "bioacoustique",
        lat: 47.481872,
        lng: 0.898578,
        ref: "BLOUISE3ZA",
        startdate: "08/11/2024",
        enddate: "",
        id: "BW76mbyw",
      },
      {
        name: "SM2-3 Point C - Zone Charlie",
        type: "bioacoustique",
        lat: 47.481997,
        lng: 0.900816,
        ref: "SM23ZC",
        startdate: "08/11/2024",
        enddate: "",
        id: "Em_KtgL0",
      },
      {
        name: "SM2-4 Point D - Zone Delta",
        type: "bioacoustique",
        lat: 47.481409,
        lng: 0.900863,
        ref: "SM24ZD",
        startdate: "08/11/2024",
        enddate: "",
        id: "tjMBH9Dd",
      },
      {
        name: "IC01 point A - zone alpha",
        type: "cam_gros_animaux",
        lat: 47.481872,
        lng: 0.898578,
        ref: "IC01ZA",
        startdate: "08/11/2024",
        enddate: "",
        id: "stiWxDJV",
      },
      {
        name: "IC02 -Point D - Zone Delta",
        type: "cam_gros_animaux",
        lat: 47.481409,
        lng: 0.900863,
        ref: "IC02ZD",
        startdate: "08/11/2024",
        enddate: "",
        id: "-MFSTV0T",
      },
      {
        name: "M01 Point B - Zone Beta",
        type: "cam_gros_animaux",
        lat: 47.484734,
        lng: 0.899455,
        ref: "M01ZB",
        startdate: "08/11/2024",
        enddate: "",
        id: "tg-jSqT0",
      },
      {
        name: "M02 Point C - Zone Charlie",
        type: "cam_gros_animaux",
        lat: 47.481997,
        lng: 0.900816,
        ref: "M02ZC",
        startdate: "08/11/2024",
        enddate: "",
        id: "7nFg6LvV",
      },
    ],
  },
  lapeyruche: {
    client: "lapeyruche",
    configcategory: "sensors",
    sensors: [
      {
        name: "Analyse de sol 1",
        type: "analyse_sol",
        lat: 44.71611030752402,
        lng: -0.38466364145278936,
        ref: "4GAPC/4GAMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "XBFMkXdw",
      },
      {
        name: "Analyse de sol 2",
        type: "analyse_sol",
        lat: 44.71748450437027,
        lng: -0.38199752569198614,
        ref: "3APC/3AMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "8agmfpBf",
      },
      {
        name: "Analyse de sol 3",
        type: "analyse_sol",
        lat: 44.717465444997785,
        lng: -0.3819492459297181,
        ref: "1APC/1AMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "U6HQsUiN",
      },
      {
        name: "Analyse de sol 4",
        type: "analyse_sol",
        lat: 44.71742732623402,
        lng: -0.3818070888519287,
        ref: "2APC/2AMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "Kxs1SREb",
      },
      {
        name: "Analyse de sol 5",
        type: "analyse_sol",
        lat: 44.71526976327075,
        lng: -0.3798463940620423,
        ref: "5JPC/5JMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "hhO8Rusf",
      },
      {
        name: "Analyse de sol 6",
        type: "analyse_sol",
        lat: 44.71730926366115,
        lng: -0.37720084272070636,
        ref: "7BGPC/7BGMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "yBmJBgSK",
      },
      {
        name: "Analyse de sol 7",
        type: "analyse_sol",
        lat: 44.717324861227944,
        lng: -0.375662226049236,
        ref: "8BPC/8BMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "dqsEF-cm",
      },
      {
        name: "Analyse de sol 8",
        type: "analyse_sol",
        lat: 44.71405761038231,
        lng: -0.3799160023046523,
        ref: "6GRPC/6GRMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "TJjDSvKR",
      },
      {
        name: "Bioaccoustique 10",
        type: "bioacoustique",
        lat: 44.71523909265383,
        lng: -0.38025442189720154,
        ref: "10JO",
        startdate: "12/11/2024",
        enddate: "",
        id: "z_96rC5w",
      },
      {
        name: "Bioacoustique 1",
        type: "bioacoustique",
        lat: 44.71617701686245,
        lng: -0.38529396057128906,
        ref: "2GAO",
        startdate: "07/03/2024",
        enddate: "",
        id: "Jxu_lw0Y",
      },
      {
        name: "Bioacoustique 2",
        type: "bioacoustique",
        lat: 44.71683648218438,
        lng: -0.3806108236312867,
        ref: "3AEO",
        startdate: "07/03/2024",
        enddate: "",
        id: "bBR_Bc0U",
      },
      {
        name: "Bioacoustique 3",
        type: "bioacoustique",
        lat: 44.717297722249356,
        lng: -0.377081036567688,
        ref: "4BGO",
        startdate: "07/03/2024",
        enddate: "",
        id: "tmfQ8lBX",
      },
      {
        name: "Bioacoustique 4",
        type: "bioacoustique",
        lat: 44.71521697209525,
        lng: -0.38035191918690325,
        ref: "5JO",
        startdate: "07/03/2024",
        enddate: "",
        id: "jdk2y555",
      },
      {
        name: "Bioacoustique 5",
        type: "bioacoustique",
        lat: 44.71489427810585,
        lng: -0.3774672746658326,
        ref: "6BO",
        startdate: "07/03/2024",
        enddate: "",
        id: "mvJYhp93",
      },
      {
        name: "Bioacoustique 6",
        type: "bioacoustique",
        lat: 44.717682721471796,
        lng: -0.38320720195770264,
        ref: "1CO",
        startdate: "07/03/2024",
        enddate: "",
        id: "Vn2gUtmf",
      },
      {
        name: "Camera gros mammifères 1",
        type: "cam_gros_animaux",
        lat: 44.71681551663953,
        lng: -0.38646876811981207,
        ref: "11EGM",
        startdate: "07/03/2024",
        enddate: "",
        id: "-II4ySy6",
      },
      {
        name: "Camera gros mammifères 2",
        type: "cam_gros_animaux",
        lat: 44.716449571360464,
        lng: -0.38333619105888284,
        ref: "1VGM",
        startdate: "21/02/2024",
        enddate: "",
        id: "ZWtjVK-Y",
      },
      {
        name: "Camera gros mammifères 4",
        type: "cam_gros_animaux",
        lat: 44.71623800819026,
        lng: -0.3791195154190064,
        ref: "3TCGM",
        startdate: "21/02/2024",
        enddate: "",
        id: "skZx_x5H",
      },
      {
        name: "Camera gros mammifères 5",
        type: "cam_gros_animaux",
        lat: 44.714905714238206,
        lng: -0.3774109482765198,
        ref: "4BGM",
        startdate: "21/02/2024",
        enddate: "",
        id: "3hK6XRom",
      },
      {
        name: "Caméra hauteur 1",
        type: "cam_gros_animaux",
        lat: 44.71769476605495,
        lng: -0.3828484681609146,
        ref: "17CH",
        startdate: "17/10/2024",
        enddate: "",
        id: "9Bv3s5HY",
      },
      {
        name: "Caméra hauteur 2",
        type: "cam_gros_animaux",
        lat: 44.716926186547646,
        lng: -0.3833174244063909,
        ref: "16VH",
        startdate: "17/10/2024",
        enddate: "",
        id: "aScGMD40",
      },
      {
        name: "Camera petits animaux 5",
        type: "cam_gros_animaux",
        lat: 44.71725388554176,
        lng: -0.37714339624429055,
        ref: "7BGGM",
        startdate: "19/04/2024",
        enddate: "",
        id: "hRNqseXP",
      },
      {
        name: "Camera petits animaux 2",
        type: "cam_petits_animaux",
        lat: 44.717941927426175,
        lng: -0.38043379783630377,
        ref: "6PFPM",
        startdate: "21/02/2024",
        enddate: "",
        id: "Dt8n5Pfv",
      },
      {
        name: "Camera petits animaux 4",
        type: "cam_petits_animaux",
        lat: 44.71530426533436,
        lng: -0.38029108738121054,
        ref: "8JPM",
        startdate: "21/02/2024",
        enddate: "",
        id: "501tMMTJ",
      },
      {
        name: "Camera petits animaux 6",
        type: "cam_petits_animaux",
        lat: 44.71420607577128,
        lng: -0.38218880900237734,
        ref: "2HPM",
        startdate: "10/06/2024",
        enddate: "",
        id: "_YZ42j9m",
      },
      {
        name: "Camera petits animaux 8",
        type: "cam_petits_animaux",
        lat: 44.71615986303992,
        lng: -0.3832532011772961,
        ref: "5VPM",
        startdate: "19/04/2024",
        enddate: "",
        id: "RspdvI8W",
      },
    ],
  },
  etrechy: {
    client: "etrechy",
    configcategory: "sensors",
    sensors: [
      {
        name: "SONG ETRE001",
        type: "bioacoustique",
        lat: 48.49285615568737,
        lng: 2.183449562716127,
        ref: "SONGETRE001",
        startdate: "13/08/2024",
        enddate: "",
        id: "7iqzS2XB",
      },
      {
        name: "ETRE004 CY95",
        type: "cam_petits_animaux",
        lat: 48.492821613075805,
        lng: 2.1834173651672195,
        ref: "ETRE004",
        startdate: "22/06/2024",
        enddate: "",
        id: "jyGIc7YX",
      },
      {
        name: "ETRE005 CY95",
        type: "cam_petits_animaux",
        lat: 48.492840148068225,
        lng: 2.1835568543480255,
        ref: "ETRE005",
        startdate: "22/06/2024",
        enddate: "",
        id: "enSZokvD",
      },
      {
        name: "ETRE007 CY95",
        type: "cam_petits_animaux",
        lat: 48.49287103756557,
        lng: 2.1844445132521266,
        ref: "ETRE007",
        startdate: "06/08/2024",
        enddate: "",
        id: "OOvjPkqD",
      },
    ],
  },
};

const __itemsstats__ = {
  louisedelavalliere: {
    column_counts: { sound: 6091, gbif: 983, inpn: 7771, camera: 1177 },
    total_rows: 16102,
  },
  torpez: { column_counts: { inpn: 3229, gbif: 15439 }, total_rows: 18668 },
  lapeyruche: {
    column_counts: {
      satellite: 440,
      Rapace: 1,
      inpn: 1982,
      parcellaire: 35,
      sol: 16,
      ultrason: 61,
      adn: 8,
      sound: 42708,
      gbif: 9030,
      pratique: 44,
      camera: 3476,
      microbio: 8,
    },
    total_rows: 58947,
  },
  porquerolles: {
    column_counts: { inpn: 34993, gbif: 43814 },
    total_rows: 78807,
  },
  maris: { column_counts: { gbif: 19710 }, total_rows: 19710 },
  etrechy: {
    column_counts: { sound: 3075, gbif: 8061, inpn: 5708, camera: 950 },
    total_rows: 17795,
  },
};

function getItemStatePimped() {
  let ret = JSON.parse(JSON.stringify(__itemsstats__)); // deep copy

  function safeGetValue(collection, clientName, key) {
    if (clientName in collection) {
      if (
        "column_counts" in collection[clientName] &&
        key in collection[clientName].column_counts
      ) {
        return collection[clientName].column_counts[key];
      }
    }
    return 0;
  }

  for (const clientName in ret) {
    let clientStats = ret[clientName];
    const gbif = safeGetValue(ret, clientName, "gbif");
    const inpn = safeGetValue(ret, clientName, "inpn");
    const open_data = gbif + inpn;
    const camera = safeGetValue(ret, clientName, "camera");
    const sound = safeGetValue(ret, clientName, "sound");
    const total_media = camera + sound + open_data;

    clientStats.column_counts.open_data = open_data;
    clientStats.column_counts.total_media = total_media;
  }
  return ret;
}

function imgkeyToImage(imgkey: string) {
  return `./dashboards/newimg/${imgkey}.png`;
}

const HorizontalBox = ({ pretitle, title, value, image }) => {
  return (
    <div className="horizontal-box">
      <img src={imgkeyToImage(image)} className="horizontal-box-image" />
      <div>
        <div className="horizontal-box-pretitle">{pretitle}</div>
        <div className="horizontal-box-title">{title}</div>
      </div>
      <div className="horizontal-box-value">{value}</div>
    </div>
  );
};

const VerticalBox = ({ pretitle, title, value, image }) => {
  return (
    <div className="vertical-box">
      <img src={imgkeyToImage(image)} className="vertical-box-image" />
      <div className="vertical-box-pretitle">{pretitle}</div>
      <div className="vertical-box-title">{title}</div>
      <div className="vertical-box-value">{value}</div>
    </div>
  );
};

function itemsStatsHasValue(clientName: string, datatype: string) {
  let clientStats = getItemStatePimped()[clientName];
  if (!(datatype in clientStats.column_counts)) {
    return false;
  }
  if (clientStats.column_counts[datatype] === undefined) {
    return false;
  }
  return clientStats.column_counts[datatype] > 0;
}
function getItemsStatsValue(clientName: string, datatype: string) {
  if (itemsStatsHasValue(clientName, datatype)) {
    let clientStats = getItemStatePimped()[clientName];
    return clientStats.column_counts[datatype];
  }
  return 0;
}

function TableRowItemHorizontal(props: {
  clientName: string;
  pretitle: string;
  title: string;
  jsonkey: string;
  image: string;
}) {
  const { clientName, pretitle, title, jsonkey, image } = props;
  if (!clientName || !jsonkey) {
    return <></>;
  }
  if (!itemsStatsHasValue(clientName, jsonkey)) {
    return <></>;
  }
  return (
    <HorizontalBox
      pretitle={pretitle}
      title={title}
      value={getItemsStatsValue(clientName, jsonkey)}
      image={image}
    />
  );
}
function TableRowItemVertical(props: {
  clientName: string;
  pretitle: string;
  title: string;
  jsonkey: string;
  image: string;
}) {
  const { clientName, pretitle, title, jsonkey, image } = props;
  return (
    <VerticalBox
      pretitle={pretitle}
      title={title}
      value={getItemsStatsValue(clientName, jsonkey)}
      image={image}
    />
  );
}

function sensorsStatsHasValue(clientName: string, datatype: string) {
  if (!clientName || !__sensorsstats__[clientName]) {
    return false;
  }

  if (!__sensorsstats__[clientName].sensors) {
    return false;
  }

  const sensorCount = __sensorsstats__[clientName].sensors.filter(
    (sensor) => sensor.type === datatype
  ).length;

  return sensorCount > 0;
}

function getSensorsStatsValue(clientName: string, datatype: string) {
  if (sensorsStatsHasValue(clientName, datatype)) {
    const sensorStats = __sensorsstats__[clientName];
    if (sensorStats && sensorStats.sensors) {
      const sensorCount = sensorStats.sensors.filter(
        (sensor) => sensor.type === datatype
      ).length;
      return sensorCount;
    }
  }
  return 0;
}

function TableRowSensorVertical(props: {
  clientName: string;
  pretitle: string;
  title: string;
  jsonkey: string;
  image: string;
}) {
  const { clientName, pretitle, title, jsonkey, image } = props;
  if (!clientName || !jsonkey) {
    return <></>;
  }
  if (!sensorsStatsHasValue(clientName, jsonkey)) {
    return <></>;
  }
  return (
    <VerticalBox
      pretitle={pretitle}
      title={title}
      value={getSensorsStatsValue(clientName, jsonkey)}
      image={image}
    />
  );
}

function SiteDashboard() {
  const [clientName, setClientName] = useState<string | null>(null);
  const [siteData, setSiteData] = useState<
    (typeof __itemsstats__)[keyof typeof __itemsstats__] | null
  >(null);

  useEffect(() => {
    const fetchClientName = async () => {
      const name = await getClientName();
      setClientName(name);
    };
    fetchClientName();
  }, []);

  useEffect(() => {
    if (
      clientName &&
      __itemsstats__[clientName as keyof typeof __itemsstats__]
    ) {
      setSiteData(__itemsstats__[clientName as keyof typeof __itemsstats__]);
    }
  }, [clientName]);

  if (!clientName) {
    return (
      <div className="mainpage">
        <h2 style={{ textAlign: "center" }}>Site Dashboard</h2>
        <p>Chargement des données...</p>
      </div>
    );
  }

  if (!siteData) {
    return (
      <div className="mainpage">
        <h2 style={{ textAlign: "center" }}>Site Dashboard</h2>
        <p>Pas encore de données disponibles.</p>
      </div>
    );
  }

  return (
    <div className="mainpage">
      <div className="section-title">Statistiques du site {clientName}</div>
      <div className="dashboard-container">
        <TableRowItemHorizontal
          clientName={clientName}
          pretitle="Nombre de"
          title="Medias"
          jsonkey="total_media"
          image="total"
        />
        <TableRowItemHorizontal
          clientName={clientName}
          pretitle="Nombre de"
          title="Videos/Images"
          jsonkey="camera"
          image="camera"
        />
        <TableRowItemHorizontal
          clientName={clientName}
          pretitle="Nombre de"
          title="Sons"
          jsonkey="sound"
          image="sound"
        />
        <TableRowItemHorizontal
          clientName={clientName}
          pretitle="Nombre de données"
          title="Open data"
          jsonkey="open_data"
          image="opendata"
        />
      </div>

      <div
        className="dashboard-container"
        style={{ display: "flex", gap: "20px" }}
      >
        <div className="section">
          <div className="section-title">Pratiques agricoles</div>
          <div className="dashboard-container">
            <TableRowItemVertical
              clientName={clientName}
              pretitle="Nombre de données"
              title="Pratiques parcellaires"
              jsonkey="parcellaire"
              image="parcellaire"
            />
            <TableRowItemVertical
              clientName={clientName}
              pretitle="Nombre de données"
              title="Pratiques ponctuelles"
              jsonkey="pratique"
              image="pratiques"
            />
          </div>
        </div>

        <div className="section">
          <div className="section-title">Données de sol</div>
          <div className="dashboard-container">
            <TableRowItemVertical
              clientName={clientName}
              pretitle="Nombre de données"
              title="Microbiologie"
              jsonkey="microbio"
              image="microbio"
            />
            <TableRowItemVertical
              clientName={clientName}
              pretitle="Nombre de données"
              title="ADNe"
              jsonkey="adn"
              image="adn"
            />
            <TableRowItemVertical
              clientName={clientName}
              pretitle="Nombre de données"
              title="Analyses physico-chimie"
              jsonkey="adn"
              image="physico-chimie"
            />
          </div>
        </div>
      </div>

      <div className="section-title">Capteurs</div>
      <div className="dashboard-container">
        <TableRowSensorVertical
          clientName={clientName}
          pretitle="Nombre de capteurs"
          title="Bioacoustique"
          jsonkey="bioacoustique"
          image="sound"
        />
        <TableRowSensorVertical
          clientName={clientName}
          pretitle="Nombre de capteurs"
          title="Bioacoustique ultrason"
          jsonkey="bioacoustique_ultrason"
          image="ultrasound"
        />
        <TableRowSensorVertical
          clientName={clientName}
          pretitle="Nombre de cameras"
          title="Gros animaux"
          jsonkey="cam_gros_animaux"
          image="grosanimaux"
        />
        <TableRowSensorVertical
          clientName={clientName}
          pretitle="Nombre de cameras"
          title="Insectes"
          jsonkey="cam_insectes"
          image="insecte"
        />
        <TableRowSensorVertical
          clientName={clientName}
          pretitle="Nombre de cameras"
          title="Petits animaux"
          jsonkey="cam_petits_animaux"
          image="petitsanimaux"
        />
      </div>
    </div>
  );
}

export default SiteDashboard;
