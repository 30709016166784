import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { v4 as uuidv4 } from "uuid";
import { postOccurrence, getAllOccurrences } from "../OccurrencesApi";
import { getPropertyCoordinates, getPropertyBoundary } from "../ConfigProvider";
import CopyButton  from "../components/CopyButton";
import { getTilelayerUrl } from "../mapHelpers";

function MapComponent({ onMapLoad }) {
  const map = useMap();
  useEffect(() => {
    if (map) {
      onMapLoad(map);
    }
  }, [map, onMapLoad]);
  return null;
}

export default function ImportGBIF() {
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState([
    44.7163158285053, -0.3834068326026641,
  ]);
  const defaultZoom = 16;
  const [zoomLevel, setZoomLevel] = useState(defaultZoom);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [logInfos, setLogInfos] = useState("...");

  async function displayPropertyBoundaries(map) {
    try {
      const propertyBoundaries = await getPropertyBoundary();
      let propertyBoundariesGeoJSON = JSON.parse(propertyBoundaries);

      const propertyBoundaryLayer = L.geoJSON(propertyBoundariesGeoJSON, {
        style: {
          color: "#A3B75E",
          fillColor: "lightblue",
          fillOpacity: 0,
          interactive: false,
          weight: 5,
        },
      });
      propertyBoundaryLayer.addTo(map);
    } catch (error) {
      console.error("Error fetching property boundaries: ", error);
    }
  }

  function displayMapCenterAndZoomlevel(map) {
    getPropertyCoordinates().then((coords) => {
      setMapCenter([coords.latitude, coords.longitude]);

      let zoom = coords.zoomLevel || defaultZoom;
      map.setView([coords.latitude, coords.longitude], zoom);
    });
  }

  async function getData() {
    const items = await getAllOccurrences();
    return items;
  }

  const MapEvents = () => {
    const map = useMap();

    useEffect(() => {
      if (mapInitialized) {
        return;
      }
      displayPropertyBoundaries(map);
      displayMapCenterAndZoomlevel(map);
      setMapInitialized(true);
    }, [map]);

    return null;
  };

  async function putOccurence(e) {
    // On pousse dans dynamo
    const myUUID = uuidv4();
    try {
      let dataToPost = {
        uuid: myUUID,
        epoch: e.epoch,
        latitude: e.latitude,
        longitude: e.longitude,
        type: "gbif",
        title: e.title,
        text: e.text,
      };
      await postOccurrence(dataToPost);
    } catch (error) {
      console.log("PUT call failed: ", error);
    }
  }

  async function createOccurrence(observation) {
    let e = {};
    e.title = `${observation.species || "Inconnue"}`;
    e.text = `Enregistré: ${
      observation.eventDate
        ? new Intl.DateTimeFormat("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(observation.eventDate))
        : "Inconnu"
    }`;

    //e.text += `<br/><br/><a href="https://www.gbif.org/species/${observation.speciesKey}" target="_blank">Plus d'infos sur GBIF</a>`

    e.epoch = new Date(observation.eventDate).getTime() / 1000;
    e.latitude = observation.decimalLatitude;
    e.longitude = observation.decimalLongitude;

    e.text = observation.speciesKey;

    await putOccurence(e);
  }

  function logBbox() {
    if (!map) return;
    setLogInfos("...");
    let localLogInfos = "";

    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    localLogInfos = "SouthWest:" + sw + "\nNorthEast:" + ne + "\n\nBbox format sw.lng, sw.lat, ne.lng, ne.lat:" + [sw.lng, sw.lat, ne.lng, ne.lat].join(",") + "\nsouthWest lat:" + sw.lat + "\nsouthWest lng:" + sw.lng + "\nnorthEast lat:" + ne.lat + "\nnorthEast lng:" + ne.lng;
    console.log("SouthWest:", sw);
    console.log("NorthEast:", ne);
    console.log(
      "Bbox format sw.lng, sw.lat, ne.lng, ne.lat:",
      [sw.lng, sw.lat, ne.lng, ne.lat].join(",")
    );
    console.log("southWest lat:", sw.lat);
    console.log("southWest lng:", sw.lng);
    console.log("northEast lat:", ne.lat);
    console.log("northEast lng:", ne.lng);
    
    localLogInfos = localLogInfos + "\n";
    localLogInfos = localLogInfos + "\nZoom level:" + map.getZoom();
    console.log("Zoom level:", map.getZoom());
    localLogInfos = localLogInfos + "\nCenter:" + map.getCenter();
    localLogInfos = localLogInfos + "\n";
    console.log("Center:", map.getCenter());

    const bbox = [sw.lng, sw.lat, ne.lng, ne.lat].join(",");
    const mapshaperCommand = `mapshaper {input}.shp -proj wgs84 -clip bbox=${bbox} -o format=geojson output.geojson`;
    localLogInfos = localLogInfos + "\nMapshaper command:" + mapshaperCommand;
    console.log("Mapshaper command:", mapshaperCommand);

    localLogInfos = localLogInfos + "\n";
    // Calculate and log WKT (Well-Known Text) representation of the bounding box
    const wkt = `POLYGON((${sw.lng} ${sw.lat}, ${ne.lng} ${sw.lat}, ${ne.lng} ${ne.lat}, ${sw.lng} ${ne.lat}, ${sw.lng} ${sw.lat}))`;
    localLogInfos = localLogInfos + "\nWKT:\n" + wkt;
    console.log("WKT", wkt);



    setLogInfos(localLogInfos);
  }

  async function getDataCount() {
    if (!map) return;

    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    console.log("SouthWest:", sw);
    console.log("NorthEast:", ne);

    // Constructing the Mapshaper command
    const bbox = [sw.lng, sw.lat, ne.lng, ne.lat].join(",");
    const mapshaperCommand = `mapshaper {input}.shp -proj wgs84 -clip bbox=${bbox} -o format=geojson output.geojson`;
    console.log("Mapshaper command:", mapshaperCommand);

    const nbEventToFetch = 300;

    const gbifApiUrl = `https://api.gbif.org/v1/occurrence/search?year=2000,2025&decimalLatitude=${sw.lat},${ne.lat}&decimalLongitude=${sw.lng},${ne.lng}&limit=${nbEventToFetch}`;

    try {
      const response = await fetch(gbifApiUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch GBIF data: ${response.statusText}`);
      }

      const data = await response.json();

      return data.count;
    } catch (error) {
      console.error("Error fetching GBIF data:", error);
      return null; // You can return null or handle the error in a different way as needed.
    }
  }

  function btnCount() {
    getDataCount().then((data) => {
      if (data !== null) {
        alert(`Total observations: ${data}`);
      } else {
        alert("Failed to fetch GBIF data.");
      }
    });
  }

  async function writeToDynamo(data) {
    if (data) {
      const batchSize = 5;
      for (let i = 0; i < data.length; i += batchSize) {
        // Create a batch of promises
        const batch = data
          .slice(i, i + batchSize)
          .map((observation) => createOccurrence(observation));
        // Wait for the entire batch to be processed before moving on
        await Promise.all(batch);
        //console.log(`Batch ${i / batchSize} processed.`);
      }

      console.log("All occurrences have been created.");
    } else {
      console.log("Failed to fetch or process GBIF data.");
    }
  }

  function filterObs(observations, data) {
    // Check if data is an array
    if (!Array.isArray(data)) {
      console.error("data is not an array");
      return [];
    }

    const convertedObservations = observations.map((observation) => {
      return {
        ...observation, // Spread the rest of the properties
        epoch: observation.eventDate
          ? new Date(observation.eventDate).getTime() / 1000
          : null,
        latitude: observation.decimalLatitude,
        longitude: observation.decimalLongitude,
        title: `${observation.species || "Inconnue"}`,
      };
    });

    /*
        console.log(convertedObservations[0],data[0])
        console.log(convertedObservations[0].epoch == data[0].epoch)
        console.log(convertedObservations[0].latitude == data[0].latitude) 
        console.log(convertedObservations[0].longitude == data[0].longitude)
        console.log(convertedObservations[0].title == data[0].title)*/

    return convertedObservations.filter((convertedObs) => {
      // Check if the observation already exists in the data
      return !data.some(
        (d) =>
          d.latitude === convertedObs.latitude &&
          d.longitude === convertedObs.longitude &&
          d.epoch === convertedObs.epoch &&
          d.title === convertedObs.title
      );
    });
  }

  function getE1cJsonFromGbifObservation(obs) {
    const e1cJson = {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [obs.decimalLongitude, obs.decimalLatitude],
        type: "Point",
      },
      medias: [], // Example: This would need to be populated based on your application's logic
      startdate: obs.eventDate, // Directly using the event date from GBIF
      enddate: obs.eventDate, // Assuming end date is the same as start date, adjust as necessary
      title: obs.species, // Using the species name for the title
      text: `<a href="https://www.gbif.org/species/${obs.speciesKey}" target="_blank">Voir sur GBIF</a>`,
      datatype: "gbif", // Assuming a static value, adjust as necessary
    };
    return e1cJson;
  }

  function formatAsUTCZ(date) {
    if (!date) {
      return null;
    }

    // Regex to match YYYY-MM-DDTHH:MM format without timezone
    const regexYYYYMMDDHHMM = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    // Regex to match YYYY-MM-DDTHH:MM:SS format without timezone
    const regexYYYYMMDDHHMMSS = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    // Regex to match YYYY-MM-DDTHH:MMZ and YYYY-MM-DDTHH:MM:SSZ format with timezone
    const regexWithZ = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?Z$/;
    // Regex to match YYYY-MM-DDTHH:MM:SS.SSSZ format with milliseconds and timezone
    const regexYYYYMMDDHHMMSSSZ =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    if (/^\d{4}$/.test(date)) {
      return `${date}-01-01T00:00:00Z`;
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return `${date}T00:00:00Z`;
    } else if (regexYYYYMMDDHHMM.test(date)) {
      return `${date}:00Z`;
    } else if (
      regexYYYYMMDDHHMMSS.test(date) ||
      regexWithZ.test(date) ||
      regexYYYYMMDDHHMMSSSZ.test(date)
    ) {
      // If date is in one of the detailed formats, ensure it ends with 'Z'
      return date.endsWith("Z") ? date : `${date}Z`;
    }
    // Throw an error if the date format does not match any of the expected patterns
    console.log(`Invalid date format: ${date}`);
    return null;
  }

  function getE1cItemFromGbifObservation(obs) {
    let startdate = obs.eventDate;
    let enddate = obs.eventDate;

    // Check if eventDate is a range
    if (obs.eventDate && obs.eventDate.includes("/")) {
      const dates = obs.eventDate.split("/");
      startdate = dates[0];
      enddate = dates[1];
    }

    // Check if the startdate and enddate are just years (4 digits)
    // and convert them into a full date format (YYYY-MM-DD)
    if (/^\d{4}$/.test(startdate)) {
      startdate = `${startdate}-01-01`; // Assuming January 1st of the start year
    }
    if (/^\d{4}$/.test(enddate)) {
      enddate = `${enddate}-12-31`; // Assuming December 31st of the end year
    }

    startdate = formatAsUTCZ(startdate);
    enddate = formatAsUTCZ(enddate);

    if (!startdate || !enddate) {
      return null;
    }

    const e1cItem = {
      wkt: `POINT(${obs.decimalLongitude} ${obs.decimalLatitude})`,
      medias: [],
      startdate: startdate,
      enddate: enddate,
      title: obs.species,
      text: `<a href="https://www.gbif.org/species/${obs.speciesKey}" target="_blank">Voir sur GBIF</a>`,
      datatype: "gbif",
    };

    return e1cItem;
  }

  function convertGbifObservationsToItemCollection(gbifObservations) {
    // Map each GBIF observation to an E1cJson feature
    const data = []; // Initialize an empty array to hold the transformed items

    gbifObservations.forEach((obs) => {
      const item = getE1cItemFromGbifObservation(obs); // Transform each observation

      if (item && item.startdate && item.enddate && item.title) {
        data.push(item); // Add the transformed item to the new array
      }
    });

    console.log(data);

    return data;
  }

  function convertGbifObservationsToFeatureCollection(gbifObservations) {
    // Map each GBIF observation to an E1cJson feature
    const features = gbifObservations.map((obs) =>
      getE1cJsonFromGbifObservation(obs)
    );

    //console.log(features)

    // Wrap the features in a FeatureCollection
    const featureCollection = {
      type: "FeatureCollection",
      features: features,
    };

    return featureCollection;
  }

  function launchDownloadJson(json) {
    // Convert the featureCollection to a JSON string
    const jsonStr = JSON.stringify(json, null, 2);

    // Create a Blob from the JSON String
    const blob = new Blob([jsonStr], { type: "application/json" });

    // Generate a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.json"; // Name of the file to download
    document.body.appendChild(a); // Append the link to the document
    a.click(); // Trigger the download

    // Clean up by revoking the Blob URL and removing the temporary link
    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  async function getE1cItemCollection() {
    if (!map) return;

    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    let offset = 0;

    let obsArray = [];
    while (true) {
      const gbifApiUrl = `https://api.gbif.org/v1/occurrence/search?year=2000,2025&decimalLatitude=${sw.lat},${ne.lat}&decimalLongitude=${sw.lng},${ne.lng}&limit=300&offset=${offset}`;

      const maxRetries = 20; // Maximum number of retries
      let currentAttempt = 0;
      let success = false;

      while (currentAttempt < maxRetries && !success) {
        try {
          console.log("fetch:", offset, "attempt:", currentAttempt + 1);
          const response = await fetchWithTimeout(gbifApiUrl);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch GBIF data: ${response.statusText}`
            );
          }

          const data = await response.json();
          const observations = data.results;

          for (const obs of observations) {
            obsArray.push(obs);
          }

          console.log(`Fetched ${offset + observations.length}/${data.count}`);

          if (offset + observations.length >= data.count) {
            break;
          }

          offset += 300; // Increase the offset for the next request.
          success = true; // Break out of the retry loop on success
        } catch (error) {
          console.error("Error fetching GBIF data:", error);
          currentAttempt++;
          await sleep(60000); // Wait longer before retrying
        }
      }

      if (!success) {
        console.error("Failed to fetch data after multiple attempts.");
        break; // or continue; depending on whether you want to stop or skip to the next batch
      }

      await sleep(1); // Sleep between successful fetches to avoid rate limits
    }
    const itemArray = convertGbifObservationsToItemCollection(obsArray);
    console.log(itemArray);
    launchDownloadJson(itemArray);
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function fetchWithTimeout(url, timeout = 10000) {
    // Set default timeout
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(url, { signal: controller.signal });
    clearTimeout(id);
    return response;
  }

  async function getE1cJson() {
    if (!map) return;

    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    let offset = 0;

    const uniqueLocations = new Set(); // Initialize outside the loop

    let obsArray = [];
    while (true) {
      const gbifApiUrl = `https://api.gbif.org/v1/occurrence/search?decimalLatitude=${sw.lat},${ne.lat}&decimalLongitude=${sw.lng},${ne.lng}&limit=300&offset=${offset}`;

      try {
        console.log("fetch:", offset);
        const response = await fetch(gbifApiUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch GBIF data: ${response.statusText}`);
        }

        const data = await response.json();
        const observations = data.results;

        //console.log(observations);

        for (const obs of observations) {
          obsArray.push(obs);
        }

        console.log(`Fetched ${offset + observations.length}/${data.count}`);

        if (offset + observations.length >= data.count) {
          break;
        }

        offset += 300; // Increase the offset for the next request.
      } catch (error) {
        console.error("Error fetching GBIF data:", error);
        return null; // You can return null or handle the error in a different way as needed.
      }
    }
    const featureCollection =
      convertGbifObservationsToFeatureCollection(obsArray);
    console.log(featureCollection);
    launchDownloadJson(featureCollection);
  }

  async function addGBIFData() {
    if (!map) return;

    const existingData = await getData();
    //console.log(existingData);

    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    let offset = 0;

    const uniqueLocations = new Set(); // Initialize outside the loop

    while (true) {
      const gbifApiUrl = `https://api.gbif.org/v1/occurrence/search?decimalLatitude=${sw.lat},${ne.lat}&decimalLongitude=${sw.lng},${ne.lng}&limit=300&offset=${offset}`;

      try {
        console.log("fetch:", offset);
        const response = await fetch(gbifApiUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch GBIF data: ${response.statusText}`);
        }

        const data = await response.json();
        const observations = data.results;

        let filteredObs = filterObs(observations, existingData);

        // Filter out duplicates based on latitude and longitude
        /* Demande LUC : On garde tout le GBIF
                filteredObs = filteredObs.filter(obs => {
                    const locationKey = `${obs.decimalLatitude},${obs.decimalLongitude}`;
                    if (!uniqueLocations.has(locationKey)) {
                        uniqueLocations.add(locationKey);
                        return true;
                    }
                    return false;
                });*/

        console.log("unique observations:", filteredObs.length);

        if (filteredObs.length > 0) {
          await writeToDynamo(filteredObs);
        }

        console.log(`Fetched ${offset + observations.length}/${data.count}`);

        if (offset + observations.length >= data.count) {
          break;
        }

        offset += 300; // Increase the offset for the next request.
      } catch (error) {
        console.error("Error fetching GBIF data:", error);
        return null; // You can return null or handle the error in a different way as needed.
      }
    }
  }

  return (
    <div className="content">
      <div className="nav">
        <button onClick={getE1cItemCollection}>getE1cItemCollection</button>
        <button onClick={btnCount}>Count GBIF</button>
        <button onClick={logBbox}>
          Get map infos
        </button>
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <CopyButton val={logInfos} />
        </div>
        <textarea
          readOnly
          style={{
            width: '100%',
            height: '900px',
            resize: 'vertical',
            marginBottom: '10px',
            fontSize: '12px',
          }}
          value={logInfos}
        >
        </textarea>
      </div>
      <div className="main">
        <div className="filter"></div>
      </div>

      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ width: "100%", height: "100%", paddingTop: "100px" }}>
          <MapContainer
            center={mapCenter}
            zoom={zoomLevel}
            style={{ height: "100%" }}
          >
            <TileLayer url={getTilelayerUrl("esri")} />
            <MapComponent onMapLoad={setMap} />
            <MapEvents />
          </MapContainer>
        </div>
      </div>
    </div>
  );
}
