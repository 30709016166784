import { get } from "../../ConfigProvider";

function getUserAccountsRessourceName(): string {
  return "userAccounts";
}

function removeAdmins(userAccounts: []) {
  return userAccounts.filter(
    (userAccount) => !userAccount.Username.startsWith("e1c")
  );
}

function orderByUsername(userAccounts: []) {
  return userAccounts.sort((a, b) => a.Username.localeCompare(b.Username));
}

async function getUserAccounts(): Promise<[]> {
  //return getUserAccountMock();
  const url = getUserAccountsRessourceName();
  const data = await get(url);

  const users = data.users;
  const usersWithoutAdmins = removeAdmins(users);
  const usersOrdered = orderByUsername(usersWithoutAdmins);

  return usersOrdered;
}

export { getUserAccounts };
