import { get } from "../ConfigProvider";

const getDatasets = async function () {
  const ds = await get("datasets");
  return ds;
};

const getAvailableDatasetFromImportedDataset = function (
  availableDatasets,
  importedDataset: string
) {
  return availableDatasets.find((d) => d.Name == importedDataset);
};

const getImportedDatasets = async function () {
  const ds = await getDatasets();
  const dsinmap = ds.importedDatasetsInMap;
  const availableDatasets = ds.availableDatasets;

  const sorteddsinmap = dsinmap.sort((dsAname, dsBname) => {
    const ads = getAvailableDatasetFromImportedDataset(
      availableDatasets,
      dsAname
    );
    const bds = getAvailableDatasetFromImportedDataset(
      availableDatasets,
      dsBname
    );
    if (!ads || !bds) {
      return 0;
    }
    return new Date(ads.CreateTime) - new Date(bds.CreateTime);
  });

  return sorteddsinmap;
};

const getOldFormatHumanReadableName = function (datasetName: string) {
  const versionIndex = datasetName.indexOf("__version__");
  if (versionIndex !== -1) {
    return datasetName.substring(0, versionIndex);
  }
  return datasetName;
};

const getDatatypeHumanReadableName = function (datatype: string) {
  if (datatype == "camera") {
    return "images/vidéos";
  }
  if (datatype == "sound") {
    return "audio";
  }
  return datatype;
};

const getDateHumanReadableName = function (date: string) {
  const convertStringToDate = function (dateString: string) {
    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);
    return new Date(`${year}-${month}-${day}`);
  };

  const dateObj = convertStringToDate(date);
  return dateObj.toLocaleDateString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const getNewFormatHumanReadableName = function (datasetName: string) {
  const versionNumber = datasetName.split("__version__")[1];
  const parts = datasetName.split("__version__")[0].split("_");

  const datatype = parts[1];
  const startDate = parts[2];
  const endDate = parts[3];

  const humanReadableDatatype = getDatatypeHumanReadableName(datatype);
  const humanReadableStartDate = getDateHumanReadableName(startDate);
  const humanReadableEndDate = getDateHumanReadableName(endDate);

  const toDisplayVersionNumber =
    versionNumber && versionNumber != "1" ? `(version ${versionNumber})` : "";

  return `Campagne ${humanReadableDatatype} du ${humanReadableStartDate} au ${humanReadableEndDate} ${toDisplayVersionNumber}`;
};

const getHumanReadableDatasetName = function (datasetName: string) {
  const newFormatRegex =
    /^[a-zA-Z0-9]+_[a-zA-Z0-9]+_\d{8}_\d{8}__version__\d+$/;
  if (newFormatRegex.test(datasetName)) {
    return getNewFormatHumanReadableName(datasetName);
  }
  return getOldFormatHumanReadableName(datasetName);
};

export { getDatasets, getImportedDatasets, getHumanReadableDatasetName };
