import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getClientName } from "../../ConfigProvider";

function SiteConfigHome() {
  const [clientName, setClientName] = useState(null);

  useEffect(() => {
    getClientName().then(setClientName);
  }, []);

  return (
    <div className="mainpage">
      <h2 style={{ textAlign: "center", padding: "10px" }}>
        Administration du site {clientName}:
      </h2>

      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Link
          to="/admin/siteConfig/mapConfig"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-map-location-dot"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Configuration de la carte</span>
          </div>
        </Link>
        <Link
          to="/admin/siteConfig/parcellesCadastralesConfig"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-layer-group"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Configuration des parcelles cadastrales</span>
          </div>
        </Link>

        <Link
          to="/admin/siteConfig/dashboardConfig"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-chart-line"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Dashboards</span>
          </div>
        </Link>

        <Link
          to="/admin/compteUtilisateurs"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-user-gear"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Comptes utilisateurs</span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SiteConfigHome;
