import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useAdmin from "../hooks/useAdmin";
import { getClientName } from "../ConfigProvider";
import { getDashboards as getDashboardsApi } from "../dataProvider/dashboardProvider";

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  padding: 3rem;
`;

const DashboardItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const DashboardImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DashboardLabel = styled.h3`
  text-align: center;
  font-size: 1.2rem;
  margin: 0;
`;

interface DashboardInfo {
  id: string;
  label: string;
  imageUrl: string;
  link: string;
  isLapeyrucheOnly?: boolean;
  isLouisedelavalliereOnly?: boolean;
  isDetail?: boolean;
  isAdminOnly?: boolean;
}

function dashboardApiToDashboardInfo(dashboard: any): DashboardInfo {
  let ret = {
    id: dashboard.id,
    label: dashboard.label,
    imageUrl: "/dashboardImages/" + dashboard.image,
    link: "/ssDashboard?dashboardId=" + dashboard.id,
    isLapeyrucheOnly: false, //deprecated
    isLouisedelavalliereOnly: false, //deprecated
    isDetail: true,
    isAdminOnly: dashboard.onlyAdmin,
  };
  return ret;
}

const dashboards: DashboardInfo[] = [
  {
    id: "site",
    label: "Statistiques globales du site",
    imageUrl: "/dashboardImages/dashboardSite.jpg",
    link: "/siteDashboard",
  },
  {
    id: "allSites",
    label: "Statistiques globales, tous les sites",
    imageUrl: "/dashboardImages/dashboardAllSites.jpg",
    link: "/allSitesDashboard",
    isAdminOnly: true,
  },
];

function shouldDisplayDashboard(
  dashboard: DashboardInfo,
  canBeAdmin: boolean,
  clientName: string
): boolean {
  if (dashboard.isAdminOnly && !canBeAdmin) {
    return false;
  }
  if (dashboard.isLapeyrucheOnly && clientName !== "lapeyruche") {
    return false;
  }
  if (
    dashboard.isLouisedelavalliereOnly &&
    clientName !== "louisedelavalliere"
  ) {
    return false;
  }
  return true;
}

async function getDetailDashboardsFromDb(
  canBeAdmin: boolean
): Promise<DashboardInfo[]> {
  const dashboards = await getDashboardsApi();
  let ret = dashboards.map(dashboardApiToDashboardInfo);
  if (!canBeAdmin) {
    ret = ret.filter((d) => !d.isAdminOnly);
  }
  return ret;
}

function getGeneralDashboards(
  canBeAdmin: boolean,
  clientName: string
): DashboardInfo[] {
  return dashboards.filter(
    (dashboard) =>
      !dashboard.isDetail &&
      shouldDisplayDashboard(dashboard, canBeAdmin, clientName)
  );
}

const DashboardHome: React.FC = () => {
  const [clientName, setClientName] = useState<string | null>(null);
  const [dashboardsFromDb, setDashboardsFromDb] = useState<DashboardInfo[]>([]);
  const { canBeAdmin } = useAdmin();

  useEffect(() => {
    const fetchClientName = async () => {
      const name = await getClientName();
      setClientName(name);
    };
    const fetchDashboardsFromDb = async () => {
      const dashboards = await getDetailDashboardsFromDb(canBeAdmin);
      setDashboardsFromDb(dashboards);
    };
    fetchClientName();
    fetchDashboardsFromDb();
  }, [canBeAdmin]);

  return (
    <div className="mainpage">
      <h2 style={{ textAlign: "center", margin: "3m 0" }}>Dashboards:</h2>
      <DashboardGrid>
        {getGeneralDashboards(canBeAdmin, clientName).map((dashboard) => {
          return (
            <DashboardItem key={dashboard.id} to={dashboard.link}>
              <DashboardImage src={dashboard.imageUrl} alt={dashboard.label} />
              <DashboardLabel>{dashboard.label}</DashboardLabel>
            </DashboardItem>
          );
        })}
      </DashboardGrid>
      <hr
        style={{
          width: "80%",
          margin: "2rem auto",
          border: "none",
          borderTop: "1px solid #ccc",
        }}
      />
      <DashboardGrid>
        {dashboardsFromDb.map((dashboard) => {
          return (
            <DashboardItem key={dashboard.id} to={dashboard.link}>
              {dashboard.isAdminOnly && <div>(admin only)</div>}
              <DashboardImage src={dashboard.imageUrl} />
              <DashboardLabel>{dashboard.label}</DashboardLabel>
            </DashboardItem>
          );
        })}
      </DashboardGrid>
    </div>
  );
};

export default DashboardHome;
