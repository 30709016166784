export function getClientList() {
  const clients = [
    "lapeyruche",
    "maris",
    "louisedelavalliere",
    "etrechy",
    "torpez",
    "diane",
  ];
  return clients;
}
