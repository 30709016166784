import { getConfig, writeConfig } from "../ConfigProvider";

const DASHBOARD_CONFIG_CAT = "dashboards";

async function getDashboards() {
  const dashboards = await getConfig(DASHBOARD_CONFIG_CAT);
  if (!dashboards || !dashboards.dashboards) {
    return [];
  }
  const parsed = JSON.parse(dashboards.dashboards);
  return parsed;
}

function setDashboards(dashboards: any) {
  const towrite = { dashboards: JSON.stringify(dashboards) };
  return writeConfig(DASHBOARD_CONFIG_CAT, towrite);
}

export { getDashboards, setDashboards };
