import React from "react";
function EditButton({ onClick }) {
  return (
    <i
      className="fa fa-edit"
      aria-hidden="true"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    ></i>
  );
}

export default EditButton;
