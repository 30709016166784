import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { getTilelayerUrl } from "../../mapHelpers";
import { LatLngExpression, LatLng } from "leaflet";
import { useMapEvents, useMap } from "react-leaflet";
import {
  getPropertyBoundary,
  getPropertyCoordinates,
  setPropertyCoordinates,
} from "../../ConfigProvider";
import Spinner from "../../components/Spinner";

function MapEvents({
  positionChangedCallback,
}: {
  positionChangedCallback: (center: LatLngExpression, zoom: number) => void;
}) {
  const map = useMapEvents({
    moveend: () => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      positionChangedCallback([center.lat, center.lng], zoom);
    },
  });
  return null;
}

function MapCenter({
  center,
  zoom,
}: {
  center: LatLngExpression;
  zoom: number;
}) {
  const map = useMap();

  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);

  return null;
}

function Map({
  center,
  zoom,
  positionChangedCallback,
  propertyBoundary,
}: {
  center: LatLngExpression;
  zoom: number;
  positionChangedCallback: (center: LatLngExpression, zoom: number) => void;
  propertyBoundary: any;
}) {
  return (
    <MapContainer
      style={{ height: "90vh", width: "80vw", borderRadius: "10px" }}
      maxZoom={18}
    >
      <MapCenter center={center} zoom={zoom} />
      <MapEvents positionChangedCallback={positionChangedCallback} />
      <TileLayer url={getTilelayerUrl("esri")} />
      {propertyBoundary && Object.keys(propertyBoundary).length > 0 && (
        <GeoJSON data={propertyBoundary} />
      )}
    </MapContainer>
  );
}

/////////////////////////////////////////////////////////////

function MapConfig() {
  function getFranceLatLng() {
    return [46.60625, 2.43596];
  }
  const [center, setCenter] = useState<LatLngExpression>(getFranceLatLng());
  const [zoom, setZoom] = useState<number>(7);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [propertyBoundary, setPropertyBoundary] = useState<any>([]);

  function getFirstCenterOfPropertyBoundary(): LatLngExpression | null {
    function getFirstFeature(geometry: any) {
      return geometry;
    }

    console.log("propertyBoundary", propertyBoundary);
    if (propertyBoundary && Object.keys(propertyBoundary).length > 0) {
      const firstFeature = getFirstFeature(propertyBoundary);
      if (firstFeature) {
        console.log("firstFeature", firstFeature);
        const firstPoint = firstFeature.geometry.coordinates[0][0];
        return new LatLng(firstPoint[1], firstPoint[0]);
      }
    }
    return null;
  }

  function zoomToPropertyBoundary() {
    const center = getFirstCenterOfPropertyBoundary();
    if (center) {
      setCenter(center);
      setZoom(15);
    } else {
      alert("Aucune limite de propriété trouvée");
    }
  }

  const positionChangedCallback = (center: LatLngExpression, zoom: number) => {
    setCenter(center);
    setZoom(zoom);
  };

  const SavePosition = () => {
    setIsSaving(true);
    const latlngExpression = center as LatLngExpression;
    const latlng = new LatLng(latlngExpression[0], latlngExpression[1]);
    const lat = latlng.lat;
    const lng = latlng.lng;
    console.log("saving", lat, lng, zoom);
    setPropertyCoordinates(lat, lng, zoom).then(() => {
      setIsSaving(false);
    });
  };

  useEffect(() => {
    getPropertyCoordinates().then((property) => {
      console.log("property", property);
      if (property) {
        if (property.latitude && property.longitude) {
          const latlng: LatLngExpression = [
            property.latitude,
            property.longitude,
          ];
          setCenter(latlng);
          if (property.zoomLevel) {
            setZoom(property.zoomLevel);
          }
        }
        getPropertyBoundary().then((propertyBoundaries) => {
          if (propertyBoundaries) {
            let propertyBoundariesGeoJSON = JSON.parse(propertyBoundaries);
            setPropertyBoundary(propertyBoundariesGeoJSON);
          }
        });
      }
    });
  }, []);
  return (
    <div className="mainpage">
      <div style={{ display: "flex" }}>
        <Map
          center={center}
          zoom={zoom}
          positionChangedCallback={positionChangedCallback}
          propertyBoundary={propertyBoundary}
        />
        <div style={{ width: "20vw", padding: "10px", textAlign: "center" }}>
          <hr />
          <br />
          <a
            onClick={() => {
              setCenter(getFranceLatLng());
              setZoom(7);
            }}
            style={{ cursor: "pointer" }}
            href="#"
          >
            Centrer sur la France
          </a>
          <br />
          <br />
          <a
            onClick={zoomToPropertyBoundary}
            style={{ cursor: "pointer" }}
            href="#"
          >
            Centrer sur les coordonnées cadastrales
          </a>
          <br />
          <br />
          <hr />
          Positionner la carte puis cliquer sur enregistrer
          <br />
          <br />
          {isSaving && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
          {!isSaving && (
            <button
              style={{
                padding: "5px 10px",
                fontSize: "16px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={SavePosition}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default MapConfig;
