import React, { useEffect, useState } from "react";
import {
  getAllSensorsAsAdmin,
  ClientSensor,
  filterSensorsGetOnlyPhysicalsAndActives,
} from "../../dataProvider/sensorProviders";
import Sensor from "../../sensorPage/Sensor";
import Spinner from "../../components/Spinner";
import { getCampaignsStatus } from "../../ConfigProvider";
import CopyButton from "../../components/CopyButton";

interface SensorLine {
  client: string;
  ref: string;
  name: string;
  startdate: string;
  derniere_collecte: string;
  derniere_collecte_depuis: string;
  derniere_collecte_depuis_color: string;
  derniere_collecte_depuis_bold: string;
}

interface Campagne {
  id: string;
  state: string;
  sensor_list: string[];
}

function getClientFromCampagne(campagne: Campagne): string {
  return campagne.id.split("/")[0];
}
function getEndDateFromCampagne(campagne: Campagne): string {
  return campagne.id.split("/")[2].split("_")[1];
}
function getDateLastCollecteForCampagne(campagne: Campagne): string {
  return getEndDateFromCampagne(campagne);
}

function SensorTableHeader() {
  return (
    <thead>
      <tr>
        <th>Ref</th>
        <th>Nom</th>
        <th>Date de début</th>
        <th>Dernière collecte</th>
        <th>Dernière collecte depuis (en jours)</th>
      </tr>
    </thead>
  );
}

function SensorTableRow({ sensor }: { sensor: SensorLine }) {
  return (
    <tr>
      <td>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "5px",
          }}
        >
          {sensor.ref}
          <CopyButton val={sensor.ref} />
        </div>
      </td>
      <td>{sensor.name}</td>
      <td>{sensor.startdate}</td>
      <td>{sensor.derniere_collecte}</td>
      <td
        style={{
          color: sensor.derniere_collecte_depuis_color,
          fontWeight: sensor.derniere_collecte_depuis_bold,
        }}
      >
        {sensor.derniere_collecte_depuis}
      </td>
    </tr>
  );
}

function SensorTableBody({ sensors }: { sensors: SensorLine[] }) {
  return <tbody>{sensors.map((sensor) => SensorTableRow({ sensor }))}</tbody>;
}

function SensorTable({ sensors }: { sensors: SensorLine[] }) {
  return (
    <table className="admin-table" style={{ width: "80%", margin: "0 auto" }}>
      <SensorTableHeader />
      <SensorTableBody sensors={sensors} />
    </table>
  );
}

function sensorToSensorLine(
  client: string,
  sensor: Sensor,
  campagnes: Campagne[]
): SensorLine {
  const lastCollect = getDateLastCollecte(client, sensor, campagnes);
  const sinceInt = getSinceNbDays(lastCollect);
  let color = "black";
  if (sinceInt > 90) {
    color = "red";
  } else if (sinceInt > 60) {
    color = "orange";
  }
  const bold = color === "black" ? "normal" : "bold";

  const lastCollectStr = sinceInt === 0 ? "" : sinceInt.toString() + " j";

  return {
    client: client,
    ref: sensor.ref,
    name: sensor.name,
    startdate: sensor.startdate,
    derniere_collecte: e1cDateToDateString(lastCollect),
    derniere_collecte_depuis: lastCollectStr,
    derniere_collecte_depuis_color: color,
    derniere_collecte_depuis_bold: bold,
  };
}

function e1cDateToDateString(e1cDate: string): string {
  if (!e1cDate) {
    return "";
  }
  const day = e1cDate.substring(0, 2);
  const month = e1cDate.substring(2, 4);
  const year = e1cDate.substring(4, 8);
  return `${day}/${month}/${year}`;
}

function getSinceNbDays(lastCollecteE1cDate: string): number {
  if (!lastCollecteE1cDate) {
    return 0;
  }
  const day = lastCollecteE1cDate.substring(0, 2);
  const month = lastCollecteE1cDate.substring(2, 4);
  const year = lastCollecteE1cDate.substring(4, 8);
  const now = new Date();
  const nowDay = now.getDate();
  const nowMonth = now.getMonth() + 1;
  const nowYear = now.getFullYear();
  const diffDays = nowDay - parseInt(day);
  const diffMonths = nowMonth - parseInt(month);
  const diffYears = nowYear - parseInt(year);
  const totalDays = diffYears * 365 + diffMonths * 30 + diffDays;
  return totalDays;
}

function getDateLastCollecte(
  client: string,
  sensor: Sensor,
  campagnes: Campagne[]
): string {
  const ref = sensor.ref;
  for (const campagne of campagnes) {
    if (
      getClientFromCampagne(campagne) === client &&
      campagne.sensor_list.includes(ref)
    ) {
      return getDateLastCollecteForCampagne(campagne);
    }
  }
  return "";
}

function clientSensorsToSensorLines(
  clientSensors: ClientSensor[],
  campagnes: Campagne[]
): SensorLine[] {
  return clientSensors
    .map((clientSensor) =>
      clientSensor.sensors.map((sensor) =>
        sensorToSensorLine(clientSensor.client, sensor, campagnes)
      )
    )
    .flat();
}

function cleanStrFromJson(str: string): string {
  const cleanedStr = str.replace(/'/g, '"');
  const cleanedWithoutNewlines = cleanedStr.replace(/\n/g, "");
  return cleanedWithoutNewlines;
}

function campagnesJsonToCampagnes(campagnes: object): Campagne[] {
  const campagnesArray: Campagne[] = [];
  for (const campaign of Object.values(campagnes)) {
    campagnesArray.push(campaign);
  }
  return campagnesArray;
}

//-------------------------------------
function EtatCapteurs() {
  const [clientSensors, setClientSensors] = useState<ClientSensor[]>([]);
  const [loading, setLoading] = useState(true);
  const [campagnesStatus, setCampagnesStatus] = useState<Campagne[]>([]);

  useEffect(() => {
    getAllSensorsAsAdmin().then((sensors) => {
      setClientSensors(
        sensors.map((s) => ({
          client: s.client,
          sensors: filterSensorsGetOnlyPhysicalsAndActives(s.sensors),
        }))
      );
      fetchCampagnes();
      setLoading(false);
    });
  }, []);

  async function fetchCampagnes() {
    let campagnes = await getCampaignsStatus();
    if (!campagnes) {
      campagnes = [];
    } else {
      const campagnesJson = JSON.parse(cleanStrFromJson(campagnes));
      campagnes = campagnesJsonToCampagnes(campagnesJson);
    }
    setCampagnesStatus(campagnes);
  }

  if (loading) {
    return (
      <div className="mainpage">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mainpage">
      <div
        style={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Delai de collecte par client et capteur
      </div>
      {clientSensors.map((clientSensor) => (
        <>
          <div
            key={clientSensor.client}
            style={{ margin: "20px 0", alignItems: "center" }}
          >
            <div
              style={{
                textAlign: "center",
                width: "80%",
                margin: "20px auto",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "5px 1px 20px rgba(0, 0, 0, 0.3)",
              }}
            >
              <h2>{clientSensor.client}</h2>
              {clientSensor.sensors.length > 0 && (
                <SensorTable
                  sensors={clientSensorsToSensorLines(
                    [clientSensor],
                    campagnesStatus
                  )}
                />
              )}
              {clientSensor.sensors.length === 0 && (
                <div>(Aucun capteur actif)</div>
              )}
            </div>
          </div>
          <hr />
        </>
      ))}
    </div>
  );
}

export default EtatCapteurs;
