import React, { useState } from 'react';

const VideoPlayer = ({ url }) => {

const [error, setError] = useState(null);

  if(error) {
    return <div style={{color: 'red'}}>Erreur à la lecture du fichier</div>;
  }

  return (
    <div className="media-wrap">
      <video
        src={url}
        autoPlay
        controls
        onError={(e) => setError(e)}
      />
    </div>
  );
};

export default VideoPlayer;
