import React, { useState, useEffect } from "react";
import Editable from "../../sensorPage/Editable";
import Spinner from "../../components/Spinner";
import { getClientList } from "../ClientUtils";
import {
  getSensorsAsAdmin,
  filterSensorsGetOnlyPhysicalsAndActives,
} from "../../dataProvider/sensorProviders";
import { NextCampaign } from "../../dataProvider/nextCampaignProvider";
import Sensor from "../../sensorPage/Sensor";

function EditNextCampaignPopup({
  nextCampaign,
  onSave,
  onCancel,
}: {
  nextCampaign: NextCampaign;
  onSave: (nextCampaign: NextCampaign) => void;
  onCancel: () => void;
}) {
  const [currentCampaign, setCurrentCampaign] = useState(nextCampaign);
  const [sensorList, setSensorList] = useState<Sensor[]>([]);
  const [loadingSensorList, setLoadingSensorList] = useState(true);

  useEffect(() => {
    setCurrentCampaign(nextCampaign);
  }, [nextCampaign]);

  useEffect(() => {
    setLoadingSensorList(true);
    getSensorsAsAdmin(currentCampaign.client).then((sensorList) => {
      const onlyPhysicalsAndActives =
        filterSensorsGetOnlyPhysicalsAndActives(sensorList);
      setSensorList(onlyPhysicalsAndActives);
      setLoadingSensorList(false);
    });
  }, [currentCampaign.client]);

  function editableChanged(key, value, isInputValid) {
    const updatedCampaign = { ...currentCampaign, [key]: value };
    console.log("updatedCampaign", updatedCampaign);
    setCurrentCampaign(updatedCampaign);
  }

  function getDdClient() {
    return getClientList().map((client) => ({ key: client, value: client }));
  }

  function getDdWho() {
    return ["Margaux", "Luc"].map((who) => ({ key: who, value: who }));
  }

  return (
    <>
      <table>
        <tbody>
          <tr
            style={{
              height: "50px",
              verticalAlign: "middle",
              textAlign: "left",
            }}
          >
            <td style={{ fontWeight: "bold" }}>Date:</td>
            <td>
              <Editable
                value={currentCampaign.date}
                isEdit={true}
                type="date"
                keyy="date"
                changedCallback={editableChanged}
                forbidEmpty={true}
              />
            </td>
          </tr>



          <tr
            style={{
              height: "50px",
              verticalAlign: "middle",
              textAlign: "left",
            }}
          >
            <td style={{ fontWeight: "bold" }}>Qui:</td>
            <td>
              <Editable
                value={currentCampaign.who}
                isEdit={true}
                type="dropdown"
                keyy="who"
                changedCallback={editableChanged}
                dropdownData={getDdWho()}
                forbidEmpty={true}
              />
            </td>
          </tr>

          <tr
            style={{
              height: "50px",
              verticalAlign: "middle",
              textAlign: "left",
            }}
          >
            <td style={{ fontWeight: "bold" }}>Client:</td>
            <td>
              <Editable
                value={currentCampaign.client}
                isEdit={true}
                type="dropdown"
                keyy="client"
                changedCallback={editableChanged}
                dropdownData={getDdClient()}
                forbidEmpty={true}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Capteurs:</span>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={() => {
                const updatedCampaign = {
                  ...currentCampaign,
                  sensor_list: sensorList
                    .filter((s) => s.type.startsWith("cam"))
                    .map((s) => s.ref),
                };
                setCurrentCampaign(updatedCampaign);
              }}
            >
              Caméras
            </button>
            <button
              onClick={() => {
                const updatedCampaign = {
                  ...currentCampaign,
                  sensor_list: sensorList
                    .filter((s) => !s.type.startsWith("cam"))
                    .map((s) => s.ref),
                };
                setCurrentCampaign(updatedCampaign);
              }}
            >
              Micros
            </button>
            <button
              onClick={() => {
                const updatedCampaign = {
                  ...currentCampaign,
                  sensor_list: [],
                };
                setCurrentCampaign(updatedCampaign);
              }}
            >
              Aucun
            </button>
            <button
              onClick={() => {
                const updatedCampaign = {
                  ...currentCampaign,
                  sensor_list: sensorList.map((s) => s.ref),
                };
                setCurrentCampaign(updatedCampaign);
              }}
            >
              Tous
            </button>
          </div>
        </div>
        {loadingSensorList ? (
          <Spinner />
        ) : (
          <table className="admin-table" style={{ width: "100%" }}>
            <tbody>
              {sensorList.map((sensor) => (
                <tr key={sensor.ref}>
                  <td>{sensor.ref}</td>
                  <td>{sensor.type}</td>
                  <td>{sensor.name}</td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const updatedCampaign = {
                        ...currentCampaign,
                        sensor_list: [
                          ...currentCampaign.sensor_list,
                          sensor.ref,
                        ],
                      };
                      setCurrentCampaign(updatedCampaign);
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={currentCampaign.sensor_list.includes(sensor.ref)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          marginTop: "30px",
        }}
      >
        <button
          style={{
            padding: "5px 10px",
            fontSize: "16px",
            backgroundColor: "orange",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => {
            onCancel();
          }}
        >
          Annuler
        </button>
        <button
          style={{
            padding: "5px 10px",
            fontSize: "16px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => {
            onSave(currentCampaign);
          }}
        >
          Enregistrer
        </button>
      </div>
    </>
  );
}

export default EditNextCampaignPopup;
