import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import { getDistinctTaxref } from "./distinctTaxrefProvider";
import CopyButton from "../../components/CopyButton";

function Filters({
  onTaxrefChange,
}: {
  onTaxrefChange: (taxref: string) => void;
}) {
  const [taxref, setTaxref] = useState("");

  const handleTaxrefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxref(e.target.value);
    onTaxrefChange(e.target.value);
  };

  return (
    <div
      style={{
        paddingBottom: "10px",
        textAlign: "left",
        width: "80%",
        margin: "0 auto",
      }}
    >
      <input
        type="text"
        placeholder="Taxref E1C"
        onChange={handleTaxrefChange}
      />
    </div>
  );
}

function Content({
  distinctTaxref,
  onTaxrefChange,
}: {
  distinctTaxref: any[];
  onTaxrefChange: (taxref: string) => void;
}) {
  const handleTaxrefChange = (taxref: string) => {
    onTaxrefChange(taxref);
  };

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Filters onTaxrefChange={handleTaxrefChange} />
      <table className="admin-table" style={{ width: "80%", margin: "0 auto" }}>
        <thead>
          <tr>
            <th>Taxref E1C</th>
            {Object.keys(distinctTaxref[0] || {})
              .filter((key) => key !== "taxref")
              .map((client) => (
                <th key={client}>{client}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {distinctTaxref.map((t) => (
            <tr key={t.taxref}>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{t.taxref}</span>
                  <CopyButton val={t.taxref} />
                </div>
              </td>
              {Object.keys(t)
                .filter((key) => key !== "taxref")
                .map((client) => (
                  <td key={client}>{t[client]}</td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function DistinctTaxref() {
  const [loading, setLoading] = useState(true);
  const [distinctTaxref, setDistinctTaxref] = useState([]);
  const [filteredTaxref, setFilteredTaxref] = useState([]);

  useEffect(() => {
    getDistinctTaxref().then((distinctTaxref) => {
      setDistinctTaxref(distinctTaxref);
      setFilteredTaxref(distinctTaxref);
      setLoading(false);
    });
  }, []);

  const handleTaxrefChange = (taxref: string) => {
    const filtered = distinctTaxref.filter((t) =>
      t.taxref.toLowerCase().includes(taxref.toLowerCase())
    );
    console.log(filtered);
    setFilteredTaxref(filtered);
  };

  return (
    <div className="mainpage">
      <div
        style={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "bold",
          padding: "10px",
        }}
      >
        Taxref distincts
      </div>
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <Content
            distinctTaxref={filteredTaxref}
            onTaxrefChange={handleTaxrefChange}
          />
        )}
      </div>
    </div>
  );
}

export default DistinctTaxref;
