import { getKvConfig, writeKvConfig } from "../ConfigProvider";

const NEXT_CAMPAIGN_CONFIG_CAT = "nextCampaigns";

interface NextCampaign {
  id: string;
  date: string;
  client: string;
  sensor_list: string[];
  who: string;
}

async function getNextCampaigns(): Promise<NextCampaign[]> {
  const data = await getKvConfig(NEXT_CAMPAIGN_CONFIG_CAT);
  if (!data || !data.value) {
    return [];
  }
  const valueParsed = JSON.parse(data.value);
  return valueParsed;
}

async function setNextCampaigns(nextCampaigns: NextCampaign[]): Promise<void> {
  const towrite = nextCampaigns;
  await writeKvConfig(NEXT_CAMPAIGN_CONFIG_CAT, towrite);
}

export { NextCampaign, getNextCampaigns, setNextCampaigns };
