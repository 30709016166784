import React, { useState, useEffect } from "react";
import useAdmin from "../hooks/useAdmin";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "./allsitesdashboard.css";

const __sensorsstats__ = {
  louisedelavalliere: {
    client: "louisedelavalliere",
    configcategory: "sensors",
    sensors: [
      {
        name: "B-LOUISE4 Point B - Zone Beta",
        type: "bioacoustique",
        lat: 47.484734,
        lng: 0.899455,
        ref: "BLOUISE4ZB",
        startdate: "08/11/2024",
        enddate: "",
        id: "nKNfSk1T",
      },
      {
        name: "BLOUISE3 Point A - Zone Alpha",
        type: "bioacoustique",
        lat: 47.481872,
        lng: 0.898578,
        ref: "BLOUISE3ZA",
        startdate: "08/11/2024",
        enddate: "",
        id: "BW76mbyw",
      },
      {
        name: "SM2-3 Point C - Zone Charlie",
        type: "bioacoustique",
        lat: 47.481997,
        lng: 0.900816,
        ref: "SM23ZC",
        startdate: "08/11/2024",
        enddate: "",
        id: "Em_KtgL0",
      },
      {
        name: "SM2-4 Point D - Zone Delta",
        type: "bioacoustique",
        lat: 47.481409,
        lng: 0.900863,
        ref: "SM24ZD",
        startdate: "08/11/2024",
        enddate: "",
        id: "tjMBH9Dd",
      },
      {
        name: "IC01 point A - zone alpha",
        type: "cam_gros_animaux",
        lat: 47.481872,
        lng: 0.898578,
        ref: "IC01ZA",
        startdate: "08/11/2024",
        enddate: "",
        id: "stiWxDJV",
      },
      {
        name: "IC02 -Point D - Zone Delta",
        type: "cam_gros_animaux",
        lat: 47.481409,
        lng: 0.900863,
        ref: "IC02ZD",
        startdate: "08/11/2024",
        enddate: "",
        id: "-MFSTV0T",
      },
      {
        name: "M01 Point B - Zone Beta",
        type: "cam_gros_animaux",
        lat: 47.484734,
        lng: 0.899455,
        ref: "M01ZB",
        startdate: "08/11/2024",
        enddate: "",
        id: "tg-jSqT0",
      },
      {
        name: "M02 Point C - Zone Charlie",
        type: "cam_gros_animaux",
        lat: 47.481997,
        lng: 0.900816,
        ref: "M02ZC",
        startdate: "08/11/2024",
        enddate: "",
        id: "7nFg6LvV",
      },
    ],
  },
  lapeyruche: {
    client: "lapeyruche",
    configcategory: "sensors",
    sensors: [
      {
        name: "Analyse de sol 1",
        type: "analyse_sol",
        lat: 44.71611030752402,
        lng: -0.38466364145278936,
        ref: "4GAPC/4GAMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "XBFMkXdw",
      },
      {
        name: "Analyse de sol 2",
        type: "analyse_sol",
        lat: 44.71748450437027,
        lng: -0.38199752569198614,
        ref: "3APC/3AMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "8agmfpBf",
      },
      {
        name: "Analyse de sol 3",
        type: "analyse_sol",
        lat: 44.717465444997785,
        lng: -0.3819492459297181,
        ref: "1APC/1AMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "U6HQsUiN",
      },
      {
        name: "Analyse de sol 4",
        type: "analyse_sol",
        lat: 44.71742732623402,
        lng: -0.3818070888519287,
        ref: "2APC/2AMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "Kxs1SREb",
      },
      {
        name: "Analyse de sol 5",
        type: "analyse_sol",
        lat: 44.71526976327075,
        lng: -0.3798463940620423,
        ref: "5JPC/5JMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "hhO8Rusf",
      },
      {
        name: "Analyse de sol 6",
        type: "analyse_sol",
        lat: 44.71730926366115,
        lng: -0.37720084272070636,
        ref: "7BGPC/7BGMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "yBmJBgSK",
      },
      {
        name: "Analyse de sol 7",
        type: "analyse_sol",
        lat: 44.717324861227944,
        lng: -0.375662226049236,
        ref: "8BPC/8BMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "dqsEF-cm",
      },
      {
        name: "Analyse de sol 8",
        type: "analyse_sol",
        lat: 44.71405761038231,
        lng: -0.3799160023046523,
        ref: "6GRPC/6GRMB",
        startdate: "09/04/2024",
        enddate: "",
        id: "TJjDSvKR",
      },
      {
        name: "Bioaccoustique 10",
        type: "bioacoustique",
        lat: 44.71523909265383,
        lng: -0.38025442189720154,
        ref: "10JO",
        startdate: "12/11/2024",
        enddate: "",
        id: "z_96rC5w",
      },
      {
        name: "Bioacoustique 1",
        type: "bioacoustique",
        lat: 44.71617701686245,
        lng: -0.38529396057128906,
        ref: "2GAO",
        startdate: "07/03/2024",
        enddate: "",
        id: "Jxu_lw0Y",
      },
      {
        name: "Bioacoustique 2",
        type: "bioacoustique",
        lat: 44.71683648218438,
        lng: -0.3806108236312867,
        ref: "3AEO",
        startdate: "07/03/2024",
        enddate: "",
        id: "bBR_Bc0U",
      },
      {
        name: "Bioacoustique 3",
        type: "bioacoustique",
        lat: 44.717297722249356,
        lng: -0.377081036567688,
        ref: "4BGO",
        startdate: "07/03/2024",
        enddate: "",
        id: "tmfQ8lBX",
      },
      {
        name: "Bioacoustique 4",
        type: "bioacoustique",
        lat: 44.71521697209525,
        lng: -0.38035191918690325,
        ref: "5JO",
        startdate: "07/03/2024",
        enddate: "",
        id: "jdk2y555",
      },
      {
        name: "Bioacoustique 5",
        type: "bioacoustique",
        lat: 44.71489427810585,
        lng: -0.3774672746658326,
        ref: "6BO",
        startdate: "07/03/2024",
        enddate: "",
        id: "mvJYhp93",
      },
      {
        name: "Bioacoustique 6",
        type: "bioacoustique",
        lat: 44.717682721471796,
        lng: -0.38320720195770264,
        ref: "1CO",
        startdate: "07/03/2024",
        enddate: "",
        id: "Vn2gUtmf",
      },
      {
        name: "Camera gros mammifères 1",
        type: "cam_gros_animaux",
        lat: 44.71681551663953,
        lng: -0.38646876811981207,
        ref: "11EGM",
        startdate: "07/03/2024",
        enddate: "",
        id: "-II4ySy6",
      },
      {
        name: "Camera gros mammifères 2",
        type: "cam_gros_animaux",
        lat: 44.716449571360464,
        lng: -0.38333619105888284,
        ref: "1VGM",
        startdate: "21/02/2024",
        enddate: "",
        id: "ZWtjVK-Y",
      },
      {
        name: "Camera gros mammifères 4",
        type: "cam_gros_animaux",
        lat: 44.71623800819026,
        lng: -0.3791195154190064,
        ref: "3TCGM",
        startdate: "21/02/2024",
        enddate: "",
        id: "skZx_x5H",
      },
      {
        name: "Camera gros mammifères 5",
        type: "cam_gros_animaux",
        lat: 44.714905714238206,
        lng: -0.3774109482765198,
        ref: "4BGM",
        startdate: "21/02/2024",
        enddate: "",
        id: "3hK6XRom",
      },
      {
        name: "Caméra hauteur 1",
        type: "cam_gros_animaux",
        lat: 44.71769476605495,
        lng: -0.3828484681609146,
        ref: "17CH",
        startdate: "17/10/2024",
        enddate: "",
        id: "9Bv3s5HY",
      },
      {
        name: "Caméra hauteur 2",
        type: "cam_gros_animaux",
        lat: 44.716926186547646,
        lng: -0.3833174244063909,
        ref: "16VH",
        startdate: "17/10/2024",
        enddate: "",
        id: "aScGMD40",
      },
      {
        name: "Camera petits animaux 5",
        type: "cam_gros_animaux",
        lat: 44.71725388554176,
        lng: -0.37714339624429055,
        ref: "7BGGM",
        startdate: "19/04/2024",
        enddate: "",
        id: "hRNqseXP",
      },
      {
        name: "Camera petits animaux 2",
        type: "cam_petits_animaux",
        lat: 44.717941927426175,
        lng: -0.38043379783630377,
        ref: "6PFPM",
        startdate: "21/02/2024",
        enddate: "",
        id: "Dt8n5Pfv",
      },
      {
        name: "Camera petits animaux 4",
        type: "cam_petits_animaux",
        lat: 44.71530426533436,
        lng: -0.38029108738121054,
        ref: "8JPM",
        startdate: "21/02/2024",
        enddate: "",
        id: "501tMMTJ",
      },
      {
        name: "Camera petits animaux 6",
        type: "cam_petits_animaux",
        lat: 44.71420607577128,
        lng: -0.38218880900237734,
        ref: "2HPM",
        startdate: "10/06/2024",
        enddate: "",
        id: "_YZ42j9m",
      },
      {
        name: "Camera petits animaux 8",
        type: "cam_petits_animaux",
        lat: 44.71615986303992,
        lng: -0.3832532011772961,
        ref: "5VPM",
        startdate: "19/04/2024",
        enddate: "",
        id: "RspdvI8W",
      },
    ],
  },
  etrechy: {
    client: "etrechy",
    configcategory: "sensors",
    sensors: [
      {
        name: "SONG ETRE001",
        type: "bioacoustique",
        lat: 48.49285615568737,
        lng: 2.183449562716127,
        ref: "SONGETRE001",
        startdate: "13/08/2024",
        enddate: "",
        id: "7iqzS2XB",
      },
      {
        name: "ETRE004 CY95",
        type: "cam_petits_animaux",
        lat: 48.492821613075805,
        lng: 2.1834173651672195,
        ref: "ETRE004",
        startdate: "22/06/2024",
        enddate: "",
        id: "jyGIc7YX",
      },
      {
        name: "ETRE005 CY95",
        type: "cam_petits_animaux",
        lat: 48.492840148068225,
        lng: 2.1835568543480255,
        ref: "ETRE005",
        startdate: "22/06/2024",
        enddate: "",
        id: "enSZokvD",
      },
      {
        name: "ETRE007 CY95",
        type: "cam_petits_animaux",
        lat: 48.49287103756557,
        lng: 2.1844445132521266,
        ref: "ETRE007",
        startdate: "06/08/2024",
        enddate: "",
        id: "OOvjPkqD",
      },
    ],
  },
};
const __itemsstats__ = {
  louisedelavalliere: {
    column_counts: { sound: 6091, gbif: 983, inpn: 7771, camera: 1177 },
    total_rows: 16102,
  },
  torpez: { column_counts: { inpn: 3229, gbif: 15439 }, total_rows: 18668 },
  lapeyruche: {
    column_counts: {
      satellite: 440,
      Rapace: 1,
      inpn: 1982,
      parcellaire: 35,
      sol: 16,
      ultrason: 61,
      adn: 8,
      sound: 42708,
      gbif: 9030,
      pratique: 44,
      camera: 3476,
      microbio: 8,
    },
    total_rows: 58947,
  },
  porquerolles: {
    column_counts: { inpn: 34993, gbif: 43814 },
    total_rows: 78807,
  },
  maris: { column_counts: { gbif: 19710 }, total_rows: 19710 },
  etrechy: {
    column_counts: { sound: 3075, gbif: 8061, inpn: 5708, camera: 950 },
    total_rows: 17795,
  },
};
const __coordinates__ = {
  diane: { latitude: 44.87878766454288, longitude: -0.7300754476389026 },
  porquerolles: { latitude: 43.00420775131678, longitude: 6.212210655212402 },
  maris: { latitude: 43.31886977511792, longitude: 2.621472277230936 },
  louisedelavalliere: {
    latitude: 47.48310118631218,
    longitude: 0.8973432019455441,
  },
  lapeyruche: { latitude: 44.71568908392552, longitude: -0.38159251213073736 },
  etrechy: { latitude: 48.49291358133283, longitude: 2.183315433002431 },
  torpez: { latitude: 43.2573971024421, longitude: 6.637517806911585 },
};

function imgkeyToImage(imgkey: string) {
  return `./dashboards/newimg/${imgkey}.png`;
}

const HorizontalBox = ({ pretitle, title, value, image, isWide = false }) => {
  return (
    <div
      className={`ahorizontal-box ${isWide ? "wide" : ""}`}
      style={{ width: isWide ? "100%" : "calc(50% - 10px)" }}
    >
      <img src={imgkeyToImage(image)} className="ahorizontal-box-image" />
      <div>
        <div className="ahorizontal-box-pretitle">{pretitle}</div>
        <div className="ahorizontal-box-title">{title}</div>
      </div>
      <div className="ahorizontal-box-value">{value}</div>
    </div>
  );
};

const VerticalBox = ({ pretitle, title, value, image }) => {
  return (
    <div className="avertical-box">
      <img src={imgkeyToImage(image)} className="avertical-box-image" />
      <div className="avertical-box-pretitle">{pretitle}</div>
      <div className="avertical-box-title">{title}</div>
      <div className="avertical-box-value">{value}</div>
    </div>
  );
};

function TableRowSensorVertical(props: {
  pretitle: string;
  title: string;
  jsonkey: string;
  image: string;
}) {
  const { pretitle, title, jsonkey, image } = props;
  if (!jsonkey) {
    return <></>;
  }
  if (!sensorsStatsHasValue(jsonkey)) {
    return <></>;
  }
  return (
    <VerticalBox
      pretitle={pretitle}
      title={title}
      value={getSensorsStatsValue(jsonkey)}
      image={image}
    />
  );
}

function TableRowItemHorizontal(props: {
  pretitle: string;
  title: string;
  jsonkey: string;
  image: string;
}) {
  const { pretitle, title, jsonkey, image } = props;
  if (!jsonkey) {
    return <></>;
  }
  return (
    <HorizontalBox
      pretitle={pretitle}
      title={title}
      value={getItemsStatsValue(jsonkey)}
      image={image}
    />
  );
}
function TableRowItemVertical(props: {
  pretitle: string;
  title: string;
  jsonkey: string;
  image: string;
}) {
  const { pretitle, title, jsonkey, image } = props;
  return (
    <VerticalBox
      pretitle={pretitle}
      title={title}
      value={getItemsStatsValue(jsonkey)}
      image={image}
    />
  );
}
function getItemsStatsValue(datatype: string) {
  let sum = 0;
  for (const client in __itemsstats__) {
    if (
      __itemsstats__[client].column_counts &&
      __itemsstats__[client].column_counts[datatype]
    ) {
      sum += __itemsstats__[client].column_counts[datatype];
    }
  }
  return sum;
}

function TableRow(props: { label: string; jsonkey: string }) {
  const { label, jsonkey } = props;
  if (!jsonkey) {
    return <></>;
  }
  return (
    <tr>
      <td>{label}</td>
      <td>{getItemsStatsValue(jsonkey)}</td>
    </tr>
  );
}

function sensorsStatsHasValue(datatype: string) {
  for (const client in __sensorsstats__) {
    if (
      __sensorsstats__[client].sensors &&
      __sensorsstats__[client].sensors.some(
        (sensor) => sensor.type === datatype
      )
    ) {
      return true;
    }
  }
  return false;
}

function getSensorsStatsValue(datatype: string) {
  let sum = 0;
  for (const client in __sensorsstats__) {
    if (
      __sensorsstats__[client].sensors &&
      __sensorsstats__[client].sensors.some(
        (sensor) => sensor.type === datatype
      )
    ) {
      sum += __sensorsstats__[client].sensors.filter(
        (sensor) => sensor.type === datatype
      ).length;
    }
  }
  return sum;
}
function TableRowSensor(props: { label: string; jsonkey: string }) {
  const { label, jsonkey } = props;
  if (!jsonkey) {
    return <></>;
  }
  if (!sensorsStatsHasValue(jsonkey)) {
    return <></>;
  }
  return (
    <tr>
      <td>{label}</td>
      <td>{getSensorsStatsValue(jsonkey)}</td>
    </tr>
  );
}

function AllSitesDashboard() {
  const { canBeAdmin } = useAdmin();
  if (!canBeAdmin) {
    return (
      <div className="mainpage">
        <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
      </div>
    );
  }
  return (
    <>
      <div className="mainpage asitedashboard-mainpage">
        <div className="asection-title">Statistiques de tous les sites</div>

        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          <div style={{ width: "50%" }}>
            <HorizontalBox
              pretitle="Nombre de"
              title="Medias"
              value={Object.values(__itemsstats__).reduce(
                (sum, site) => sum + site.total_rows,
                0
              )}
              image="total"
              isWide={true}
            />

            <div className="adashboard-container">
              <HorizontalBox
                pretitle="Nombre de"
                title="Sites"
                value={Object.keys(__coordinates__).length}
                image="site"
              />

              <TableRowItemHorizontal
                pretitle="Nombre de"
                title="Videos/Images"
                jsonkey="camera"
                image="camera"
              />
              <TableRowItemHorizontal
                pretitle="Nombre de"
                title="Sons / Ultrasons"
                jsonkey="sound"
                image="sound"
              />
              <TableRowItemHorizontal
                pretitle="Nombre de données"
                title="Open data"
                jsonkey="gbif"
                image="opendata"
              />
            </div>

            <div
              className="adashboard-container"
              style={{ display: "flex", gap: "20px" }}
            >
              <div className="section">
                <div className="section-title">Pratiques agricoles</div>
                <div className="adashboard-container">
                  <TableRowItemVertical
                    pretitle="Nombre de données"
                    title="Pratiques parcellaires"
                    jsonkey="parcellaire"
                    image="parcellaire"
                  />
                  <TableRowItemVertical
                    pretitle="Nombre de données"
                    title="Pratiques ponctuelles"
                    jsonkey="pratique"
                    image="pratiques"
                  />
                </div>
              </div>

              <div className="section">
                <div className="section-title">Données de sol</div>
                <div className="adashboard-container">
                  <TableRowItemVertical
                    pretitle="Nombre de données"
                    title="Microbiologie"
                    jsonkey="microbio"
                    image="microbio"
                  />
                  <TableRowItemVertical
                    pretitle="Nombre de données"
                    title="ADNe"
                    jsonkey="adn"
                    image="adn"
                  />
                  <TableRowItemVertical
                    pretitle="Nombre de données"
                    title="Analyses physico-chimie"
                    jsonkey="adn"
                    image="physico-chimie"
                  />
                </div>
              </div>
            </div>

            <div className="section-title">Capteurs</div>
            <div className="adashboard-container">
              <TableRowSensorVertical
                pretitle="Nombre de capteurs"
                title="Bioacoustique"
                jsonkey="bioacoustique"
                image="sound"
              />
              <TableRowSensorVertical
                pretitle="Nombre de capteurs"
                title="Bioacoustique ultrason"
                jsonkey="bioacoustique_ultrason"
                image="ultrasound"
              />
              <TableRowSensorVertical
                pretitle="Nombre de cameras"
                title="Gros animaux"
                jsonkey="cam_gros_animaux"
                image="grosanimaux"
              />
              <TableRowSensorVertical
                pretitle="Nombre de cameras"
                title="Insectes"
                jsonkey="cam_insectes"
                image="insecte"
              />
              <TableRowSensorVertical
                pretitle="Nombre de cameras"
                title="Petits animaux"
                jsonkey="cam_petits_animaux"
                image="petitsanimaux"
              />
            </div>
          </div>
          <div>
            <div style={{ width: "800px", height: "850px" }}>
              <MapContainer
                center={[46.603354, 1.888334]}
                zoom={6}
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {Object.entries(__coordinates__).map(
                  ([client, coordinates]) => {
                    const customIcon = L.icon({
                      iconUrl: "marker-icon.png",
                      iconSize: [28, 28],
                      iconAnchor: [16, 32],
                      popupAnchor: [0, -32],
                    });

                    return (
                      <Marker
                        key={client}
                        position={[coordinates.latitude, coordinates.longitude]}
                        icon={customIcon}
                      >
                        <Popup>
                          <h3>{client}</h3>
                          Latitude: {coordinates.latitude.toFixed(4)}
                          <br />
                          Longitude: {coordinates.longitude.toFixed(4)}
                        </Popup>
                      </Marker>
                    );
                  }
                )}
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllSitesDashboard;
