import { embedDashboard } from "@superset-ui/embedded-sdk";
import React, { useEffect, useRef, useState } from "react";
import Spinner from "./Spinner";
import { getGetSuperSetDashboardToken } from "../ConfigProvider";

//--------------------------------
async function Embed(dashboardId: string, token: string) {
  const mountPoint = document.getElementById("superset-container");
  const supersetUrl = "https://dashboards.e1c.org";

  embedDashboard({
    id: dashboardId, // Use the id obtained from enabling embedding dashboard option
    supersetDomain: supersetUrl,
    mountPoint: mountPoint, // html element in which iframe will be mounted to show the dashboard
    fetchGuestToken: () => Promise.resolve(token),
    dashboardUiConfig: {},
  });

  var iframe = document.querySelector("iframe");
  if (iframe) {
    iframe.style.width = "100%"; // Set the width of the iframe
    iframe.style.minHeight = "95vh"; // Set the height of the iframe
  }
}

const EmbedDashboard = ({
  dashboardId,
  token,
}: {
  dashboardId: string;
  token: string;
}) => {
  const dashboard = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dashboardId && token) {
      Embed(dashboardId, token);
    }
  }, [dashboardId, token]);

  return (
    <div ref={dashboard} id="superset-container">
      <Spinner />
    </div>
  );
};
//--------------------------------
const SuperSetDashboard = ({ dashboardId }: { dashboardId: string }) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getGetSuperSetDashboardToken(dashboardId)
      .then((data) => {
        if (!data) {
          throw new Error("No data");
        }
        setToken(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : error ? (
        <div style={{ color: "red" }}>Error</div>
      ) : (
        <EmbedDashboard dashboardId={dashboardId} token={token} />
      )}
    </div>
  );
};

export default SuperSetDashboard;
