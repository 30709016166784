import React, { useState } from "react";

function Tooltip({ text }: { text: string }) {
  const [visible, setVisible] = useState(false);

  return (
    <div
      style={{ display: "inline-block", position: "relative" }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <i
        className="fa fa-info-circle"
        style={{ padding: "5px", cursor: "pointer" }}
      ></i>
      {visible && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            background: "black",
            color: "white",
            padding: "5px",
            borderRadius: "3px",
            whiteSpace: "nowrap",
            zIndex: 1000,
            fontSize: "12px",
          }}
        >
          {text.split("\n").map((line) => (
            <p>{line}</p>
          ))}
        </div>
      )}
    </div>
  );
}

export default Tooltip;
