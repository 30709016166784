import React from "react";

const Popup = ({ title, children, onClose = () => {}, style = {} }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container" style={style}>
        <div className="popup-header">
          {title && <h2>{title}</h2>}
          <button
            style={{ marginLeft: "auto" }}
            type="button"
            className="btnclose"
            aria-label="Close"
            onClick={onClose}
          />
        </div>
        <div className="popup-content">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
