import React, { useState } from "react";

const CopyButton = ({ val }: { val: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(val).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };

  const getIconClassName = () => {
    return copied ? "fa-thumbs-up" : "fa-copy";
  };

  return (
    <span
      className="material-symbols-outlined"
      onClick={handleCopyClick}
      style={{ cursor: "pointer" }}
    >
      <i className={`fa-regular ${getIconClassName()}`} />
    </span>
  );
};

export default CopyButton;
