import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import { LatLngExpression } from 'leaflet';
import { getTilelayerUrl } from "../../mapHelpers";
import { getPropertyBoundary, setPropertyBoundary as setPropertyBoundaryConfigProvider } from "../../ConfigProvider";
import { getPropertyCoordinates } from "../../ConfigProvider";
import CopyButton from "../../components/CopyButton";
import EditButton from "../../components/EditButton";


function MapCenter({ center, zoom }: { center: LatLngExpression, zoom: number }) {
  const map = useMap();
  
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);

  return null;
}

function Map({ center, zoom, positionChangedCallback, propertyBoundary }: { center: LatLngExpression, zoom: number, positionChangedCallback: (center: LatLngExpression, zoom: number) => void, propertyBoundary: any }) {
  return (
    <MapContainer
      style={{ height: "80vh", width: "60vw", borderRadius: "10px" }}
    >
      <MapCenter center={center} zoom={zoom} />
      <TileLayer url={getTilelayerUrl("esri")} />
      {propertyBoundary && Object.keys(propertyBoundary).length > 0 && (
        <GeoJSON data={propertyBoundary} />
      )}
    </MapContainer>
  );
}
////////////////////////////////////////////
function ParcelleCadastraleConfig() {
  function getFranceLatLng(): LatLngExpression {
    return [46.518989, 2.422483];
  }

  const [center, setCenter] = useState<LatLngExpression>(getFranceLatLng());
  const [zoom, setZoom] = useState(7);
  const [propertyBoundary, setPropertyBoundary] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState("");
  const [isEditingTextCorrect, setIsEditingTextCorrect] = useState(false);

  useEffect(() => {
    try {
      JSON.parse(editText);
      setIsEditingTextCorrect(true);
    } catch (e) {
      setIsEditingTextCorrect(false);
    }
  }, [editText]);

  async function save(text: string) {
    const stringifiedText = JSON.stringify(JSON.parse(text));
    console.log("save");
    console.log(stringifiedText);
    await setPropertyBoundaryConfigProvider(stringifiedText);
    location.reload();
  }

  function getPropertyBoundaryText(): string {
    if (propertyBoundary) {
      return JSON.stringify(propertyBoundary, null, 2);
    }
    return "";
  }


  useEffect(() => {
    getPropertyCoordinates().then((property) => {
      if (property) {
        if (property.latitude && property.longitude) {  
          const latlng: LatLngExpression = [property.latitude, property.longitude];
          setCenter(latlng);
          if (property.zoomLevel) {
            setZoom(property.zoomLevel);
          }
        }
        getPropertyBoundary().then((propertyBoundaries) => {
          if (propertyBoundaries) {
            let propertyBoundariesGeoJSON = JSON.parse(propertyBoundaries);
            setPropertyBoundary(propertyBoundariesGeoJSON);
          }
       });
      }
    });
  }, []);
  return (
    <div className="mainpage">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Map
          center={center}
          zoom={zoom}
          positionChangedCallback={() => {}}
          propertyBoundary={propertyBoundary}
        />
        <div style={{ width: "40vw", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>GeoJSON des parcelles cadastrales:</span>

            {!isEditing &&
              <EditButton
                onClick={() => {
                  setEditText(getPropertyBoundaryText()); 
                  setIsEditing(!isEditing);
                }}
              />}

            <CopyButton val={getPropertyBoundaryText()} />
          </div>
          {!isEditing && (
            <pre style={{ 
              width: "100%", 
            padding: "10px",
            overflow: "auto",
            fontFamily: "monospace",
            fontSize: "12px",
          }}>
              {getPropertyBoundaryText()}
            </pre>
          )}

          {isEditing && (
            <>
              <textarea 
                style={{ 
                  width: "100%",
                  minHeight: "200px",
                  resize: "vertical",
                  padding: "10px",
                  fontFamily: "monospace",
                  fontSize: "12px"
                }} 
                value={editText}
                rows={editText.split('\n').length}
                onChange={(e) => {
                  setEditText(e.target.value);
                }}
              ></textarea>
              {!isEditingTextCorrect &&
                <div style={{ color: "red" }}>
                  Le texte n'est pas correct
                </div>
              }
              {isEditingTextCorrect &&
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    style={{
                    padding: "5px 10px",
                    fontSize: "16px",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    save(editText);
                  }}
                >
                  Enregistrer
                  </button>
                </div>
              }

              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <button
                  style={{
                    padding: "5px 10px",
                    fontSize: "16px",
                    backgroundColor: "orange",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Annuler
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ParcelleCadastraleConfig;
