import { getThumbnailUrlFromTaxref, getThumbnailUrlFromTitle } from "../dataProvider/itemHelper";
import React, { useEffect, useState } from "react";

function ItemThumbnail({ item, isBig, isTiny = false }) {

  const [imgSrc, setImgSrc] = useState(getThumbnailUrlFromTaxref(item));
  const DISPLAY_STATES_TAXREF = "taxref";
  const DISPLAY_STATES_TITLE = "title";
  const [errState, setErrState] = useState(DISPLAY_STATES_TAXREF);

  useEffect(() => {
    let url = getThumbnailUrlFromTaxref(item);
    if (!url) {
      url = getThumbnailUrlFromTitle(item);
    }
    setImgSrc(url);
  }, [item]);

  let width = isBig ? "500px" : "200px";
  width = isTiny ? "4rem" : width;

  let height = "auto";
  height = isTiny ? "2rem" : height;


  function getPawnUrl() {
    let ret = "thumbnail/paw.png";
    return ret;
  }

  const onError = () => {
    let url = '';
    console.log('error')
    if (errState == DISPLAY_STATES_TAXREF) {
      console.log('url = getThumbnailUrlFromTitle(item);')
      url = getThumbnailUrlFromTitle(item);
      setErrState(DISPLAY_STATES_TITLE);
    }
    else {
      url = getPawnUrl();
      console.log('url = paw;')
    }

    url = url + `?retry=${Date.now()}`;


    setImgSrc(url);
    console.log('setting url:', url)
  };


  return (
    <img
      src={imgSrc}
      alt="ERROR"
      style={{ width, height, borderRadius: "20px" }}
      onError={onError}
    />
  );
}

function BigItemThumbnail({ item }) {
  return <ItemThumbnail item={item} isBig={true} />;
}

function SmallItemThumbnail({ item }) {
  return <ItemThumbnail item={item} isBig={false} />;
}

function TinyItemThumbnail({ item }) {
  return <ItemThumbnail item={item} isBig={false} isTiny={true} />;
}

export { BigItemThumbnail, SmallItemThumbnail, TinyItemThumbnail };
