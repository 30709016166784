import { GBIFexplorerComponent } from "./GBIFexplorerComponent";
import React from "react";

function GBIFexplorerPage() {
  return (
    <div className="mainpage">
      <div
        style={{
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "bold",
          padding: "20px",
        }}
      >
        GBIF explorer
      </div>
      <GBIFexplorerComponent />
    </div>
  );
}

export default GBIFexplorerPage;
