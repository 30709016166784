import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./routes/root";
import MapPage from "./routes/MapPage";
import Home from "./routes/Home";
import ImportGbif from "./import/ImportGbif.jsx";
import ImportBiophonia from "./import/ImportBiophonia.jsx";
import ImportCameratrap from "./import/ImportCameratrap.jsx";
import ImportINPN from "./import/ImportINPN.jsx";
import Test from "./routes/test.tsx";
import { I18n } from "aws-amplify/utils";
import { Authenticator } from "@aws-amplify/ui-react";
import Datasets from "./routes/Datasets.jsx";
import "@aws-amplify/ui-react/styles.css";
import SensorPage from "./sensorPage/SensorPage.tsx";
import Demodsepage from "./demodse/Demodsepage.tsx";
import TablePage from "./tablepage/TablePage.tsx";
import ItemPage from "./itempage/ItemPage.tsx";
import AllSitesDashboard from "./dashboards/AllSitesDashboard.tsx";
import DashboardHome from "./dashboards/DashboardHome.tsx";
import BatsHome from "./dashboards/chauvesouris/BatsHome.tsx";
import BatsEspeces from "./dashboards/chauvesouris/BatsEspeces.tsx";
import SiteDashboard from "./dashboards/SiteDashboard.tsx";
import AdminHome from "./admin/adminhome.tsx";
import SuiviCampagnes from "./admin/suiviCampagnes.tsx";
import InventaireCapteurs from "./admin/inventaireCapteurs/inventaireCapteurs.tsx";
import SitePage from "./sitePage/sitePage.tsx";
import SiteConfigHome from "./admin/siteConfig/siteConfigHome.tsx";
import MapConfig from "./admin/siteConfig/mapConfig.tsx";
import ParcelleCadastraleConfig from "./admin/siteConfig/parcellesCadastralesConfig.tsx";
import SsDashboard from "./dashboards/SsDashboard.tsx";
import DashboardConfig from "./admin/siteConfig/dashboardConfig.tsx";
import EtatCapteurs from "./admin/etatCapteurs/etatCapteurs.tsx";
import ProchainesCampagnes from "./admin/prochainesCampagnes/prochainesCampagnes.tsx";
import CompteUtilisateurs from "./admin/compteUtilisateurs/compteUtilisateurs.tsx";
import DistinctTaxref from "./admin/taxref/distinctTaxref.tsx";
import GBIFexplorerPage from "./admin/taxref/GBIFexplorerPage.tsx";
import TaxrefGBIF from "./admin/taxref/taxrefGBIF.tsx";
I18n.putVocabulariesForLanguage("fr-FR", {
  "Sign in": "Se connecter",
  "Forgot your password?": "Mot de passe oublié?",
  "username is required to signIn": "Nom d'utilisateur requis",
  "Incorrect username or password.":
    "Nom d'utilisateur ou mot de passe incorrect.",
  "User does not exist.": "Utilisateur inexistant.",
});
I18n.setLanguage("fr-FR");

const components = {
  Header() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="logo_E1C_blanc_full.png" alt="logo" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <video
            src="https://video.wixstatic.com/video/cd3531_0ed2581f4b6a49268b88336f4b29596c/720p/mp4/file.mp4"
            autoPlay
            muted
            loop
          />
        </div>
      </div>
    );
  },

  Footer() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://www.every1counts.life/">Every1Counts</a>
        </div>
      </div>
    );
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Nom d'utilisateur",
      label: "Nom d'utilisateur",
    },
    password: {
      placeholder: "Mot de passe",
      label: "Mot de passe",
    },
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "map/",
        element: <MapPage />,
      },
      {
        path: "importGbif/",
        element: <ImportGbif />,
      },
      {
        path: "importBiophonia/",
        element: <ImportBiophonia />,
      },
      {
        path: "importCameraTrap/",
        element: <ImportCameratrap />,
      },
      {
        path: "ImportINPN/",
        element: <ImportINPN />,
      },
      {
        path: "test/",
        element: <Test />,
      },
      {
        path: "datasets/",
        element: <Datasets />,
      },
      {
        path: "sensors/",
        element: <SensorPage />,
      },
      {
        path: "satellite/",
        element: <Demodsepage />,
      },
      {
        path: "table/",
        element: <TablePage />,
      },
      {
        path: "i/",
        element: <ItemPage />,
      },
      {
        path: "siteDashboard/",
        element: <SiteDashboard />,
      },
      {
        path: "allSitesDashboard/",
        element: <AllSitesDashboard />,
      },
      {
        path: "dashboardsHome",
        element: <DashboardHome />,
      },
      {
        path: "batsHome",
        element: <BatsHome />,
      },
      {
        path: "batsEspeces",
        element: <BatsEspeces />,
      },
      {
        path: "admin",
        element: <AdminHome />,
      },
      {
        path: "admin/campagnes",
        element: <SuiviCampagnes />,
      },
      {
        path: "admin/inventaireCapteurs",
        element: <InventaireCapteurs />,
      },
      {
        path: "admin/etatCapteurs",
        element: <EtatCapteurs />,
      },
      {
        path: "admin/prochainesCampagnes",
        element: <ProchainesCampagnes />,
      },
      {
        path: "sitePage",
        element: <SitePage />,
      },
      {
        path: "admin/siteConfig",
        element: <SiteConfigHome />,
      },
      {
        path: "admin/siteConfig/mapConfig",
        element: <MapConfig />,
      },
      {
        path: "admin/siteConfig/parcellesCadastralesConfig",
        element: <ParcelleCadastraleConfig />,
      },
      {
        path: "ssDashboard",
        element: <SsDashboard />,
      },
      {
        path: "admin/siteConfig/dashboardConfig",
        element: <DashboardConfig />,
      },
      {
        path: "admin/compteUtilisateurs",
        element: <CompteUtilisateurs />,
      },
      {
        path: "admin/taxref/distinctTaxref",
        element: <DistinctTaxref />,
      },
      {
        path: "admin/taxref/GBIFexplorerPage",
        element: <GBIFexplorerPage />,
      },
      {
        path: "admin/taxref/taxrefGBIF",
        element: <TaxrefGBIF />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Authenticator
    components={components}
    hideSignUp="true"
    formFields={formFields}
  >
    {({ signOut, user }) => <RouterProvider router={router} />}
  </Authenticator>
);
