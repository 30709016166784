import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  GBIFexplorerComponent,
  GBIFData,
} from "../admin/taxref/GBIFexplorerComponent";
import Popup from "../components/Popup";

const Testts = () => {
  const [showPopup, setShowPopup] = useState(false);

  function selectSpecieCallback(gbifData: GBIFData) {
    console.log(gbifData);
  }

  return (
    <div className="mainpage">
      <button onClick={() => setShowPopup(true)}>Show Popup</button>
      {showPopup && (
        <Popup
          title="Test"
          style={{
            width: "80%",
            margin: "0 auto",
            maxWidth: "5000px",
            height: "800px",
            overflow: "auto",
          }}
          onClose={() => setShowPopup(false)}
        >
          <GBIFexplorerComponent
            allowSelectSpecie={true}
            selectSpecieCallback={selectSpecieCallback}
          />
        </Popup>
      )}
    </div>
  );
};

export default Testts;
