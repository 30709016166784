import { getClientList } from "../admin/ClientUtils";
import { getConfig, getConfigAsAdmin, writeConfig } from "../ConfigProvider";
import Sensor from "../sensorPage/Sensor";

const SENSOR_CONFIG_CAT = "sensors";

interface ClientSensor {
  client: string;
  sensors: Sensor[];
}

const getSensors = async function (): Promise<ClientSensor[] | null> {
  const sensors = await getConfig(SENSOR_CONFIG_CAT);
  if (!sensors || !sensors.sensors) {
    return null;
  }

  const parsed = JSON.parse(sensors.sensors);
  const sa = parsed.map((s: Sensor) => {
    return new Sensor(s);
  });
  return sa;
};

const getSensorsAsAdmin = async function (client: string): Promise<Sensor[]> {
  const sensors = await getConfigAsAdmin(SENSOR_CONFIG_CAT, client);
  if (!sensors || !sensors.sensors) {
    return [];
  }

  const parsed = JSON.parse(sensors.sensors);
  const sa = parsed.map((s: Sensor) => {
    return new Sensor(s);
  });
  return sa;
};

const getAllSensorsAsAdmin = async function (): Promise<ClientSensor[] | null> {
  const clients = await getClientList();
  const sensors = await Promise.all(
    clients.map(async (client) => {
      let sensors = await getSensorsAsAdmin(client);
      if (!sensors) {
        sensors = [];
      }
      return { client, sensors };
    })
  );
  if (!sensors) {
    return null;
  }
  return sensors;
};

const filterSensorsOnlyActives = function (sensors: Sensor[]) {
  return sensors.filter((s) => s.isActif());
};

const filterSensorsGetOnlyPhysicals = function (sensors: Sensor[]) {
  return sensors.filter((s) => s.isPhysical());
};

const filterSensorsGetOnlyPhysicalsAndActives = function (sensors: Sensor[]) {
  return sensors.filter((s) => s.isPhysical() && s.isActif());
};

const getSensorsAsAdminOnlyActives = async function (
  client: string
): Promise<Sensor[] | null> {
  const sensors = await getSensorsAsAdmin(client);
  if (!sensors) {
    return null;
  }
  return sensors.filter((s) => s.isActif());
};

const getSensorsAsAdminOnlyArchived = async function (
  client: string
): Promise<Sensor[] | null> {
  const sensors = await getSensorsAsAdmin(client);
  if (!sensors) {
    return null;
  }
  return sensors.filter((s) => s.isArchived());
};

const getSensorsAsAdminOnlyFutures = async function (
  client: string
): Promise<Sensor[] | null> {
  const sensors = await getSensorsAsAdmin(client);
  if (!sensors) {
    return null;
  }
  return sensors.filter((s) => s.isFuture());
};

const saveSensors = async function (sensorArray: Sensor[]) {
  const towrite = { sensors: JSON.stringify(sensorArray) };
  await writeConfig(SENSOR_CONFIG_CAT, towrite);
};

const newSensor = function () {
  let newSensorObj = {
    id: "",
    name: "",
    type: "cam_gros_animaux",
    lat: "",
    lng: "",
    ref: "",
    startdate: "",
    enddate: "",
  };
  const ret = new Sensor(newSensorObj);
  ret.isnew = true;

  return ret;
};

export {
  getSensors,
  saveSensors,
  newSensor,
  getSensorsAsAdmin,
  getSensorsAsAdminOnlyActives,
  getAllSensorsAsAdmin,
  ClientSensor,
  filterSensorsOnlyActives,
  filterSensorsGetOnlyPhysicals,
  filterSensorsGetOnlyPhysicalsAndActives,
};
