import React, { useState, useEffect } from "react";
import Favorite from "../components/Favorite";
import AudioPlayer from "./AudioPlayer";
import ImagePlayer from "./ImagePlayer";
import VideoPlayer from "./VideoPlayer";
import FilePlayer from "./FilePlayer";

const MediaPlayer = ({ event, closePlayer }) => {
  const [medias, setMedias] = useState([]);
  const [activeMediaIndex, setActiveMediaIndex] = useState(0); // Active media index as identifier
  const [activeMediaUrl, setActiveMediaUrl] = useState(null);

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleDateString("fr-FR", options);
  };

  // Effect to fetch media info
  useEffect(() => {
    const fetchMedias = async () => {
      const mediasWithFetchUrls = await event.getMediasWithUrls();
      setMedias(mediasWithFetchUrls);
    };

    fetchMedias();
  }, [event]);

  // Separate useEffect for fetching URLs, depends on `medias` being updated
  useEffect(() => {
    // Fetch the URL for the first media item once `medias` is populated
    if (medias.length > 0) {
      fetchMediaUrl(0);
    }
  }, [medias]);

  const forceDownload = async () => {
    const index = activeMediaIndex;
    const media = medias[index];
    const url = await media.fetchUrl();

    console.log("Downloading", url);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const contentType = response.headers.get("Content-Type");
      const blob = await response.blob();

      let fileExtension = "";
      switch (contentType) {
        case "image/jpeg":
          fileExtension = "jpg";
          break;
        case "image/png":
          fileExtension = "png";
          break;
        case "image/gif":
          fileExtension = "gif";
          break;
        case "image/bmp":
          fileExtension = "bmp";
          break;
        case "image/webp":
          fileExtension = "webp";
          break;
        case "application/pdf":
          fileExtension = "pdf";
          break;
        case "text/plain":
          fileExtension = "txt";
          break;
        case "video/mp4":
          fileExtension = "mp4";
          break;
        case "video/webm":
          fileExtension = "webm";
          break;
        case "video/ogg":
          fileExtension = "ogv";
          break;
        case "audio/mpeg":
          fileExtension = "mp3";
          break;
        case "audio/ogg":
          fileExtension = "ogg";
          break;
        case "audio/wav":
          fileExtension = "wav";
          break;
        case "audio/webm":
          fileExtension = "webm";
          break;
        // Add more cases as needed
        default:
          fileExtension = "bin"; // default binary file
      }

      // Construct the filename with the correct extension
      const filename = `${event.title}.${fileExtension}`;

      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = downloadUrl;
      a.download = filename; // Set the filename here
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const fetchMediaUrl = async (index) => {
    const media = medias[index];

    if (media && media.fetchUrl) {
      const url = await media.fetchUrl();
      setActiveMediaUrl(url);
      setActiveMediaIndex(index);
    }
  };

  const renderMedia = (media) => {
    if (!activeMediaUrl) return null;
    console.log("activeMediaUrl", activeMediaUrl);
    console.log("media.mediatype", media.mediatype);

    switch (media.mediatype) {
      case "sound":
      case "ultrason":
        return <AudioPlayer key={activeMediaUrl} url={activeMediaUrl} />;
      case "image":
        return <ImagePlayer key={activeMediaUrl} url={activeMediaUrl} />;
      case "video":
        return <VideoPlayer key={activeMediaUrl} url={activeMediaUrl} />;
      case "file":
        return <FilePlayer key={activeMediaUrl} url={activeMediaUrl} />;
      default:
        return <p key={activeMediaUrl}>Unsupported media type</p>;
    }
  };

  const handleNextMedia = () => {
    let nextIndex = activeMediaIndex + 1;
    if (nextIndex >= medias.length) {
      nextIndex = 0;
    }
    fetchMediaUrl(nextIndex);
  };

  function getMediaClassname() {
    if (hasNoMedia()) {
      return "media-playlist-no-media";
    }

    if (event.datatype == "sound") {
      return "media-playlist-sound";
    }
    if (event.datatype == "ultrason") {
      return "media-playlist-sound";
    }

    return "media-playlist";
  }

  function hasNoMedia() {
    return event.datatype == "gbif" || event.datatype == "inpn";
  }

  function hasMedia() {
    return !hasNoMedia();
  }

  function getThumbnail() {
    if (hasNoMedia()) {
      return `thumbnail/${event.datatype}.png`;
    }
    let imageName =
      event.title
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[ ]/g, "_")
        .replace(/[’']/g, "") + ".jpg";

    return `thumbnail/${imageName}`;
  }

  function needToDisplayThumbnail() {
    if (hasNoMedia()) {
      return true;
    }
    if (event.datatype == "sound") {
      return true;
    }
    if (event.datatype == "ultrason") {
      return true;
    }
    return false;
  }

  function isDebugMode() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has("debug");
  }

  function getFileForDebug() {
    if (!activeMediaUrl) {
      return "pas de fichier";
    }
    return event.medias[activeMediaIndex].file;
  }

  const [buttonText, setButtonText] = useState("copy"); // State to manage button text
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setButtonText("Copied!"); // Change button text to indicate copying
      setTimeout(() => setButtonText("Copy"), 2000); // Revert button text after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
      setButtonText("Failed to Copy"); // Indicate failure to copy
      setTimeout(() => setButtonText("Copy"), 2000); // Revert button text after 2 seconds
    }
  };

  return (
    <div
      className="card"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "0px",
        width: "100%",
      }}
    >
      <div className="card-header text-center mb-2">
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <h2 style={{ marginTop: "15px", marginRight: "5px" }}>
            {event.title}
          </h2>
          <Favorite allowClick={false} item={event} />
          <div style={{ position: "absolute", left: "1120px", top: "80px" }}>
            <a
              className="btn btn-icon btn-noborder"
              href={`i?id=${event.id}`}
              target="_blank"
            >
              Détails
              <i
                style={{ marginLeft: "3px" }}
                className="fa-solid fa-arrow-up-right-from-square"
              ></i>
            </a>
          </div>
        </div>
        <p className="date">{formatDate(event.epoch)}</p>
        {isDebugMode() && (
          <>
            <u>id:</u>
            {event.uuid}
            <button onClick={() => copyToClipboard(event.uuid)}>
              {buttonText}
            </button>
            <br />
            <u>filename:</u>
            {getFileForDebug()}
            <button onClick={() => copyToClipboard(getFileForDebug())}>
              {buttonText}
            </button>
          </>
        )}
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closePlayer}
        />
      </div>
      <div className="card-content text-center">
        {event.text !== "" && (
          <div
            className="btn btn-icon btn-noborder"
            dangerouslySetInnerHTML={{ __html: event.text }}
          />
        )}

        <div className="row playlist">
          {hasMedia() && (
            <div className="col-list">
              <ul className="eventlist media-eventlist">
                {medias.map((media, index) => (
                  <li
                    key={index}
                    onClick={() => fetchMediaUrl(index)}
                    className={index === activeMediaIndex ? "" : "disabled"}
                  >
                    <div className="event-name">{media.label}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {needToDisplayThumbnail() && hasNoMedia() && (
            <div>
              <img src={getThumbnail()} style={{ width: "200px" }} />
            </div>
          )}
          <div className="col-media" style={{ overflowY: "scroll" }}>
            {needToDisplayThumbnail() && hasMedia() && (
              <div
                style={{
                  position: "relative",
                  left: "-100px",
                }}
              >
                <img
                  src={getThumbnail()}
                  style={{ width: "500px", borderRadius: "10px" }}
                />
              </div>
            )}
            <div className={getMediaClassname()}>
              {medias
                .filter((_, index) => index === activeMediaIndex)
                .map(renderMedia)}
            </div>
          </div>
          {medias.length > 1 && (
            <a className="btn btn-icon btn-noborder" onClick={handleNextMedia}>
              <i className="ei ei-caret-right"></i>
            </a>
          )}
        </div>
      </div>

      {hasMedia() && (
        <span
          className="btn btn-icon btn-noborder"
          onClick={forceDownload}
          style={{
            position: "absolute",
            bottom: "20px",
            right: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Telecharger
          <i style={{ marginLeft: "3px" }} className="fa-solid fa-download"></i>
        </span>
      )}
    </div>
  );
};

export default MediaPlayer;
