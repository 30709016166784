import React, { useState, useEffect } from "react";
import { getUserAccounts } from "./userAccountProvider";
import Spinner from "../../components/Spinner";

function formatDate(date: string) {
  return new Date(date).toLocaleString("fr-FR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

function CompteUtilisateurTable({ userAccounts }: { userAccounts: [] }) {
  return (
    <table className="admin-table" style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Nom d'utilisateur</th>
          <th>Date de création</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {userAccounts.map((userAccount) => (
          <tr>
            <td>{userAccount.Username}</td>
            <td>{formatDate(userAccount.UserCreateDate)}</td>
            <td style={{ width: "100px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <i
                  className="fa fa-edit"
                  aria-hidden="true"
                  style={{ cursor: "not-allowed", opacity: 0.5 }}
                  onClick={() => {}}
                ></i>

                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  style={{ cursor: "not-allowed", opacity: 0.5 }}
                  onClick={() => {}}
                ></i>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function CompteUtilisateurs() {
  const [userAccounts, setUserAccounts] = useState<[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserAccounts()
      .then(setUserAccounts)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="mainpage">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mainpage">
      <div
        style={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Comptes Utilisateurs
      </div>

      <div
        style={{
          textAlign: "center",
          width: "80%",
          margin: "20px auto",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "5px 1px 20px rgba(0, 0, 0, 0.3)",
        }}
      >
        {userAccounts.length > 0 ? (
          <CompteUtilisateurTable userAccounts={userAccounts} />
        ) : (
          <div>Aucun compte utilisateur (hors admins)</div>
        )}
      </div>
    </div>
  );
}

export default CompteUtilisateurs;
