import { get } from "../../ConfigProvider";

function getDistinctTaxrefRessourceName(): string {
  return "taxrefDistincts";
}

function orderByTaxref(distinctTaxref: any[]): any[] {
  return distinctTaxref.sort((a, b) => a.taxref.localeCompare(b.taxref));
}

async function getDistinctTaxref(): Promise<[]> {
  const url = getDistinctTaxrefRessourceName();
  const data = await get(url);
  console.log(data);

  const distinctTaxref = data.distinctTaxref;
  if (!distinctTaxref) {
    return [];
  }

  return orderByTaxref(distinctTaxref);
}

export { getDistinctTaxref };
