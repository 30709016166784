import SuperSetDashboard from "../components/SupersetDashboard";
import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import { getDashboards } from "../dataProvider/dashboardProvider";

function SsDashboard() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  function getDashboardId() {
    const params = new URLSearchParams(window.location.search);
    const dashboardId = params.get("dashboardId");
    if (!dashboardId) {
      throw new Error("Dashboard id is required");
    }
    return dashboardId;
  }

  useEffect(() => {
    const dashboardId = getDashboardId();
    getDashboards().then((dashboards) => {
      //assert the requested dashboard id is in the list
      const dashboard = dashboards?.find((d) => d.id == dashboardId);
      if (!dashboard) {
        setError(
          "Dashboard not found, please check the authorization and the id"
        );
      }
      setLoading(false);
    });
  }, []);

  return (
    <div className="mainpage">
      {loading ? (
        <Spinner />
      ) : error ? (
        <div style={{ color: "red" }}>{error}</div>
      ) : (
        <SuperSetDashboard dashboardId={getDashboardId()} />
      )}
    </div>
  );
}

export default SsDashboard;
