import React, { useState, useEffect } from "react";

export default function ModifyTaxRef({
  item,
  savedCallback,
  canceledCallback,
}) {
  const taxrefTable = {
    null: "(rien)",
    unknown: "Inconnu",
    sanglier: "Sanglier",
    chevreuil: "Chevreuil",
    vache: "Vache",
    fouine: "Fouine",
    oiseau: "Oiseau",
    chien: "Chien",
    chat: "Chat",
    renard: "Renard",
    herisson: "Hérisson",
    ecureuil: "Écureuil",
    vehicule: "Véhicule",
    "chouette hulotte": "Chouette hulotte",
    "rougequeue noir": "Rougequeue noir",
    "mesange charbonnière": "Mésange charbonnière",
    "mesange bleue": "Mésange bleue",
    "martre des pins": "Martre des pins",
    "geai des chênes": "Geai des Chênes",
    mulot: "Mulot",
    musaraigne: "Musaraigne",
    "heron garde boeuf": "Héron garde boeuf",
    "corneille noire": "Corneille noire",
    "merle noir": "Merle noir",
    blaireau: "Blaireau",
    faisan: "Faisan",
    "rougegorge familier": "Rougegorge familier",
    "gobemouche noir": "Gobemouche noir",
    "lezard des murailles": "Lézard des murailles",
    "grive musicienne": "Grive musicienne",
    "pinson des arbres": "Pinson des arbres",
    "faisan de colchide": "Faisan de colchide",
    "faisan versicolore": "Faisan versicolore",
    lievre: "Lièvre",
    "pigeon ramier": "Pigeon ramier",
    "accenteur mouchet": "Accenteur mouchet",
    "pie bavarde": "Pie Bavarde",
    "pic epeiche": "Pic Epeiche",
    "pic vert": "Pic vert",
    "etourneau sansonnet": "Étourneau sansonnet",
    "troglodyte mignon": "Troglodyte mignon",
    "rossignol philomele": "Rossignol philomèle",
  };

  function canSave() {
    if (selectedTaxref === item.taxref && currentTitle === item.title) {
      return false;
    }
    if (filteredTaxrefEntries().length === 0) {
      return false;
    }
    return true;
  }

  function getTaxrefTableOrdered() {
    return Object.entries(taxrefTable).sort((a, b) => a[1].localeCompare(b[1]));
  }

  const [selectedTaxref, setSelectedTaxref] = useState(item.taxref);
  const [currentTitle, setCurrentTitle] = useState(item.title);
  const [searchTerm, setSearchTerm] = useState("");

  function normalizeString(str: string) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }

  const filteredTaxrefEntries = function () {
    let result = getTaxrefTableOrdered().sort((a, b) => {
      const aValue = normalizeString(a[1]);
      const bValue = normalizeString(b[1]);
      const searchTermNormalized = normalizeString(searchTerm);

      // If one starts with search term and other doesn't, prioritize the one that does
      const aStartsWith = aValue.startsWith(searchTermNormalized);
      const bStartsWith = bValue.startsWith(searchTermNormalized);

      if (aStartsWith && !bStartsWith) return -1;
      if (!aStartsWith && bStartsWith) return 1;

      // If neither or both start with search term, filter by includes
      return aValue.includes(searchTermNormalized)
        ? bValue.includes(searchTermNormalized)
          ? 0
          : -1
        : bValue.includes(searchTermNormalized)
        ? 1
        : 0;
    });
    result = result.filter(([_, value]) =>
      normalizeString(value).includes(normalizeString(searchTerm))
    );
    return result;
  };

  useEffect(() => {
    console.log("selectedTaxref", selectedTaxref);
  }, [selectedTaxref]);

  useEffect(() => {
    if (searchTerm === "") {
      return;
    }
    let key = null;
    const entries = filteredTaxrefEntries();
    if (entries.length >= 1) {
      key = entries[0][0];
    }
    taxrefchangedCallback(key);
  }, [searchTerm]);

  function saveClicked() {
    let changelist = {};
    let changed = false;
    if (item.taxref !== selectedTaxref) {
      changelist["properties.classification.taxrefManual"] = selectedTaxref;
      changed = true;
    }
    if (item.title !== currentTitle) {
      changelist.title = currentTitle;
      changed = true;
    }

    if (!changed) {
      return canceledCallback();
    }
    savedCallback(changelist);
  }

  function taxrefchangedCallback(newTaxref) {
    setSelectedTaxref(newTaxref);
    if (newTaxref === "null") {
      setCurrentTitle("");
    } else {
      setCurrentTitle(taxrefTable[newTaxref]);
    }
  }

  return (
    <div>
      <hr />
      taxref actuel:{" "}
      <span style={{ fontWeight: "bold" }}>
        {taxrefTable[item.taxref]} ({item.taxref})
      </span>
      <br />
      <h3>Modifier le taxref:</h3>
      <input
        type="text"
        placeholder="Rechercher..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          padding: "5px",
          width: "200px",
          marginBottom: "5px",
        }}
      />
      <select
        value={selectedTaxref}
        onChange={(e) => taxrefchangedCallback(e.target.value)}
        style={{
          padding: "5px",
          width: "200px",
        }}
      >
        {filteredTaxrefEntries().map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
      <hr />
      Titre actuel:
      <span style={{ fontWeight: "bold" }}>{item.title}</span>
      <br />
      Modifier le titre:
      <br />
      <input
        value={currentTitle}
        onChange={(e) => setCurrentTitle(e.target.value)}
      />
      {!currentTitle && (
        <>
          <br />
          <span style={{ color: "red" }}>
            Un titre vide masquera la donnée pour les clients.
          </span>
          <br />
          <span style={{ color: "red" }}>Il correspond au taxref (rien).</span>
        </>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <>
          <button onClick={canceledCallback}>Annuler</button>
          <button
            onClick={saveClicked}
            style={{ backgroundColor: "limegreen" }}
            disabled={!canSave()}
          >
            Enregistrer
          </button>
        </>
      </div>
    </div>
  );
}
